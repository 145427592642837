import './Services.css';

function Services() {
    return(
        <section className="services">
            <div id="services"></div>
            <div className="services__container">
                <h2 className="services__title">
                    SERVICES
                </h2>
                <div className="services__content">
                    <div className="card">
                        <h3 className="card__title">
                            NFT Consulting
                        </h3>
                        <p className="card__text">
                            Lets got down the rabbit hole. We offer individual NFT- and web3 consulting service for any state of knowledge. Don’t be shy, say hi!

                        </p>
                    </div>

                    <div className="card">
                        <h3 className="card__title">
                            NFT Development
                        </h3>
                        <p className="card__text">
                            Lets do this! Usecase and utility research, pixel perfect NFT design and super smart contract coding. Minting and longterm support.
                        </p>
                    </div>

                    <div className="card">
                        <h3 className="card__title">
                            NFT Community
                        </h3>
                        <p className="card__text">
                            Don't have an NFT educated fanbase yet? We help you build one! Our web3 community building comes with a spice of homegrown extra leverage.
                        </p>
                    </div>

                </div>

                <div className="services__wallpaper">
                    <div className="services__contact">
                        <h4 className="services__contact__title">Don’t be shy say hi!</h4>
                        <div className="services__contact__mail">
                            <a href="mailto:hi@nftlab.berlin">hi@nftlab.berlin</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;