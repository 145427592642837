import './Engagement.css';
import Questions from './Questions';
import { useState } from 'react';

function Engagement() {

    const [qnumber, setqnumber] = useState(0);

    function next() {
        let x = qnumber;
        let z = x+1;
        
        if(z < 9){
            setqnumber(z);
        }
        
    }

    function prev() {
        let x = qnumber;
        let z = x-1;

        if(z>=0){
            setqnumber(z);
        }
        
    }

    return(
        <section className="engagement">
            <div className="engagement_container">
                <div className="engagement_content">

                    <h1 className="engagement_title">
                        SHAPING THE AVATARZZZ ROADMAP
                    </h1>

                    <p>Lets design Newtopia: A metaverse shaped and designed by you and your fellow Avatarzzz. Lets get started:</p>

                    <div className="engagement_contentx">

                        <Questions questionNumber={qnumber}/>

                        {
                            qnumber===8 ? <></>
                            
                            :

                            <div className='btn_control'> 
                                <button className='btn_back' onClick={prev}>Go Back</button>
                                <button className='btn_sc' onClick={next}>Save and continue</button>
                            </div>
                        }
                        

                    </div>
                </div>
            </div>
                
    </section>
    
    );
}

export default Engagement;

