import './Avatarzzz.css';
import A_Glitch_Background from './Avatarz_Assets/A_Glitch_Background.mp4';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

function Avatarzzz() {

    const navigate = useNavigate();

    return(
        <section className="come">
            <div id="avatarz"></div>
            <div className="come_gra"></div>
            <div className="come_video">
                <video src={A_Glitch_Background} type="video/mp4" autoPlay="autoplay" loop="loop" playsInline="playsinline" muted="muted" />
            </div>
            <div className="come_container">
                <div className="come_content">
                    <h2 className="come_title">
                        AVATARZZZ NFT
                    </h2>
                    <p className="come_text">
                        Avatarzzz is a limited NFT collection of 10.000 unique ai generated NFTs, living on the ethereum blockchain. NFT holders can expect free (yeah!) access to future NFT projects created by this lab including those for third party brands. And there is more: NFT holders can enjoy a toll free NFT marketplace to buy, trade and exchange all of their NFTs. 
                        <br /><br />
                        Did you know: Holding an Ethereum Avatarzzz NFT will give you free access (airdrops) for future Avatarzzz NFTs on multiple fellow blockchains such as solana, polygon, flow and more. 
                        <br /><br />
                    </p>
                      
                        <div className="come_btn">
                            <p onClick={(e)=> navigate('/mint')}>GET YOUR AVATARZZZ NFT</p>
                        </div>
                </div>
            </div>
        </section>

    );
}

export default Avatarzzz;
