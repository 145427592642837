import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "./cartSlice";
import { useWeb3React } from '@web3-react/core';

import { ethers } from "ethers";
import { abi } from '../abi'
import configFile from '../configFile.json';

import { useEffect, useState } from "react";
import tc from './Cart_Assets/tc.pdf';
import dropdown from './Cart_Assets/dropdown.svg';
import { useNavigate } from 'react-router-dom';
import ShinyAvatarz from '../components/ShinyAvatarz/ShinyAvatarz';
import { useLocation } from "react-router-dom";

const Counter = () => {

    const Location = useLocation();
    //console.log("Loaction is: ",Location);
    /* 
        Starts: This is code for Modal after Minting is completed
    */
        const [openModalShinyAvatarz, setOpenModalShinyAvatarz] = useState(true);
        const [test, setTest] = useState(100);
    /* 
        Ends: This is code for Modal after Minting is completed
    */

    /* 
        Starts: Code to check if user have provided email before minting. If not dim the color. Use button with className="nftCart_buy nftCart_buy_more">
    */
        const [email, setEmail] = useState("");
        let value;
        const handleInput = (e) => {
            value = e.target.value;

            //console.log("val: ",e.target.value);
            if(e.target.value.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi)) {
                setEmail(value);
               // console.log("MATCHED")
               // console.log(value);
            }
            else{
                setEmail("");
            }
            
         }
    /* 
        Ends: This is code for Modal after Minting is completed
    */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    var items = useSelector((state) => state.cart.itemList);

    const { active, account, library: provider } = useWeb3React(); 
    
    const [mintingStatus, setMintingStatus] = useState("");
    const [miningStatus, setMiningStatus] = useState("");

    async function mint() {
        /*
        Mint Logic as follows
        1. Check that there exist only 1 item in cart. If > 1 do nothing -> block Minting 
        2. If above condition is satisfied fetch the items.name and convert to int
        3. Check if the User has connected to wallet
        4. If wallet is connected, proceed to call mint functionality from contract 
        5. else ask to connect their wallet
        */
        
        //console.log("ITEMS IS: ", items);

        if(items.length === 1){
            //console.log(`Thank You, Minting procedure is starting for your choosen NFT: Avatarzzz ${items[0].name}`);
            if(active){
                console.log("YOU ARE CONNECTED TO METAMASK");
                
                let tempxx = document.getElementById('helloemail').value;
                console.log("TEMPXXXX: ", tempxx);

                //console.log("Prov: ", provider)
                const signer = provider.getSigner();
                const contractAddress = configFile.CONTRACT_ADDRESS;
                const contract = new ethers.Contract(contractAddress, abi, signer);
            
                const temp = items[0].name.slice(10);
                console.log("Item to be minted: ", temp);

                try {

                    /*  Starts: MetaMask */

                    //let tx = await contract.safeMint(temp, {value: ethers.utils.parseEther("0.05")});
                    //console.log("tx",tx);

                    /*  Ends: MetaMask */
                    
                    /* starts: infura fix */
                    setMintingStatus("Processing");
                    

                    /*
                        Starts: Testing chainLink price fees from other contract
                    */

                    const contractAdd = "0x8AC04C6AaEf30bfC8E5e216E1b1b98a4DBa361C3";
                    const providesr = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_INFURA_MAINNET);
                    let CONT = new ethers.Contract(contractAdd, abi, providesr);
                 
                    let ETHPriceChainLink =  await CONT.getLatestPrice();
                    let tempVal = parseInt(ETHPriceChainLink._hex, 16);
                    console.log("tempval: ",tempVal);
                    //let ETHPriceUSD = Number(tempVal).toPrecision(4).replace('.', '');
                    let ETHPriceUSD1 = (tempVal.toString()).slice(0,5).replace('.', '');
                    console.log("ETHPriceUSD1: ",ETHPriceUSD1);
                    
                    let ETHPriceUSD = (parseInt(ETHPriceUSD1)+1).toString();
                    console.log("ETHPriceUSD: ",ETHPriceUSD);
                    //console.log("NEW ETHPriceUSD: ",(parseInt(ETHPriceUSD)+1).toString());

                    console.log("ETH price in usd: ", (10/(parseInt(ETHPriceUSD.slice(0,4)))));
                    const PayableValueInEth = (10/(parseInt(ETHPriceUSD.slice(0,4)))).toFixed(5); //for alchemy (walletconnect)+ 6 to round off greater ethers.util.parseEther
                    //const PayableValueInEth = Number((5/(parseInt(ETHPriceUSD.slice(0,4))))).toPrecision(4);
                    
                    let y = PayableValueInEth.slice(0,7)
                    //console.log("y: ", y)
                    let x = parseFloat(y) + 0.00004;
                    let payable = x.toString().slice(0,7);
                    console.log("payable amount", payable);
     




                    
                    const payload = await contract.populateTransaction.safeMint(temp, {value: ethers.utils.parseEther(payable)});
                    console.log("Payload: ", payload);
                    //console.log("limit:", await provider.estimateGas(payload));










                    const tx_hash = await signer.sendUncheckedTransaction(payload);
                    


                    console.log("tx: ", tx_hash)
                   //console.log(`https://goerli.etherscan.io/tx/${tx.hash}`);
                    setMintingStatus("");
                    setMiningStatus("Mining");
                    await provider.waitForTransaction(tx_hash);
                    const tx_receipt = await provider.getTransactionReceipt(tx_hash);
                    console.log(tx_receipt.status);
                    
                    
                    if(tx_receipt.status === 1)  {

                        /* Remove item from cart once minting is successfull */

                       // dispatch(cartActions.removeFromCart(items[0].name)) 


                   // let EmailFromState = useSelector((state) => state.cart.saveEmailMintState)
                    /* Starts: Save email to database */
                        try 
                        {
                        
                            let response = await fetch(`${process.env.REACT_APP_DATABASE_LINK}/savemymintmail`, {
                                method: "POST",
                                headers: {
                                "Content-Type" : "application/json"
                                },
        
                                body: JSON.stringify({
                                email, account, temp, tempxx
                                })
                            })
                        }
                        catch(e){
                            console.log(e);
                        }
                        /* Ends: Save email to database */

                        setTest(50);
                }

                else{
                    setMiningStatus("");
                    setMintingStatus("Failed");
                }

                }
                catch(e) {
                    console.log(e); 
                    setMiningStatus("");
                    setMintingStatus("Failed");
                }
            }
            else {
                console.log("PLEASE CONNECT TO METAMASK");
                dispatch(cartActions.setSaveEmailMintState(email));



                
                // setTest(50);
                // dispatch(cartActions.removeFromCart(items[0].name))
                //navigate('/connectwallet')

                /*
                    Passing state ? : Logic for redirecting to mintpage if wallet is not connected and dont hold nft
                */
                navigate('/connectwallet',  { state: {prev_route: Location.pathname} })

            }
        }
        else
        {
            console.log("Warning, Make sure you have 1 item in cart befor you proceed to mint");
        }
    }

    
    /* 
        Check if user has minted NFT
        false -> user does not have nft
        true -> use have nft
    */


    const [mintedStatus, setMintedStatus] = useState(false);

    useEffect(() => {
        //console.log("MintedStatus is xx: ", mintedStatus);

        async function status_mint(){
            setMintingStatus("");
            if(account!=null)
            {
                const contractAddress = configFile.CONTRACT_ADDRESS;
                const pro = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_INFURA_MAINNET);
                const CONTRACT = new ethers.Contract(contractAddress, abi, pro);
                //const balance = await CONTRACT.balanceOf(account);
                let x =  CONTRACT.filters.Transfer(null, account);
                const events = await CONTRACT.queryFilter(x);
               // console.log("x", events.length);
                if(events.length>0){
                    //mintedStatus.current=true;
                    setMintedStatus(true);
                   // console.log("MintedStatus is: ", mintedStatus);
                }
                else
                {
                   // mintedStatus.current=false;
                   setMintedStatus(false);
                }
            }
            else
            {
                //mintedStatus.current=false;
                setMintedStatus(false);
            }
        }
        status_mint();

        return () => {
            
        }
    },[account])

    //previously- },[])


    const [btnState, setBtnState] = useState(false);
    function handleClick(){
        setBtnState(btnState => !btnState);
    }
    let showChart = btnState ? ' show_chart': '';
    let fixedChart = btnState ? ' fixed_chart': '';
    let dropdownChart = btnState ? ' dropdown_chart': '';


    /*
        checks if the route comes for minting nft
    */

    useEffect(()=>{

        if(Location.state!=null) {
           //console.log("Hello ", Location.state)
            
            if(Location.state.prev_route === "startmintRoute") {
            //console.log("Please start Minting route");
            handleClick();
            // open mint modal
            //mint();
            }
        }

    },[])

    let EmailCheck = useSelector((state) => state.cart.saveEmailMintState);
    // console.log("EmailCheck", EmailCheck.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi));

    return(
        <>

        <div className={`nftCart${fixedChart}`}>
        <div className={`nftCart_container${showChart}`}>
            <h2>Your selection</h2>
            <ul>
                {
                    items.map(itms => (
                        <li>
                            {
                                <div className="nftCart_list">
                                    <div className="nftCart_img">
                                    {/* <img src={`https://gateway.pinata.cloud/ipfs/QmVpjwXJYjhLtUkNzBZvFSf52FESgqHY1eBcmzZi8Y5DPW/${(itms.name).substring(10)}.png`} width="50" height="50" /> */}
                                    <img src={`https://storage.googleapis.com/ethnft_compress/${(itms.name).substring(10)}.jpg`} width="50" height="50" />
                                    {itms.name}
                                    </div>

                                    <div>
                                        <p>10 USD</p>
                                        {/*<img className="usdc_coin" src={usdc_coin} />*/}
                                    </div>
                                    
                                    <button onClick={()=> dispatch(cartActions.removeFromCart(itms.name))}>remove from selection</button> 
                                </div>
                            }
                        </li>
                    ))
                }
            </ul>
            
            {
                items.length ===1 ? <p></p>: <p className="nftCart_note">Note: Max 1 NFT per Wallet. Please Remove {items.length-1} NFT from selection</p>

            }



        
            
            <div className="nftCart_email">
                Provide an Email: <input type="email" id="helloemail" pattern=".+@globex\.com" onChange={handleInput} placeholder="..." defaultValue={`${useSelector((state) => state.cart.saveEmailMintState)}`} required/>
            </div>
            <p className="nftCart_clicking">By clicking „Mint Now“, you agree to the nftlab.berlin <a href={tc} target="_blank">terms and conditions</a> Each transaction will incur non-refundable gas fees. <br></br> Note: Please make sure you have enough funds in your wallet <strong>before</strong> minting.</p>
            {/*<button className="nftCart_buy" onClick={mint}>BUY NOW</button>*/}
           
            {
                mintedStatus===true ? <p className="nftCart_note">You already minted NFT</p>:<></>
            }
            
            

            {/* Starts: Code for Minting Event Listener*/}


            {
                //mintingStatus==="Processing" ? <p className="nftCart_note">Transaction in process</p>:<></>
               
                mintingStatus==="Processing" ? <div className="nftCart_note nftCart_note_v1">
                <div className="boxes">
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                </div>
                <p>Transaction in process... <br/><></>
                     Please do not close the browser </p>
                </div>:<></>
            }

            {
               // miningStatus==="Mining" ? <p className="nftCart_note">Mining transaction ...</p>:<></>
               
               miningStatus==="Mining" ? <div className="nftCart_note nftCart_note_v1 nftCart_note_v2">
               <div className="boxes">
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
                   <div className="box"></div>
               </div>
               <p>Mining transaction. It might take several minutes ...<br/>Please do not close the browser</p>
               </div>:<></>
            }

            {
                mintingStatus==="Failed" ? <p className="nftCart_note">Transaction failed. Please check your wallet for details.</p>:<></>
            }

            {/* Ends: Code for Minting Event Listener*/}
            
            
            
            {
                items.length ===1 && (email!=="" || EmailCheck.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi)) && mintedStatus===false? 
                <button className="nftCart_buy" onClick={mint}>MINT NOW</button> 
                : 
                <button className="nftCart_buy nftCart_buy_more">MINT NOW</button>
                
            }

        </div>
        <div className='btn_cart_container animate_me w3-container w3-center w3-animate-bottom'> 
                <button className='btn_cart' onClick={handleClick}>
                    <span className="view_selection">VIEW SELECTION  <div className="count_item">[<span>{items.length}</span>]</div> </span> <span className="close_selection">CLOSE SELECTION</span>
                    {/* <span className="view_selection">VIEW SELECTION <span>[</span> <span className="count_item">{items.length} </span> <span>]</span></span> <span className="close_selection">CLOSE SELECTION</span> */}
                    <img className={`${dropdownChart}`} src={dropdown} />
                </button>
            </div>
        </div>

{ /* Starts: This is code for Modal after Minting is completed */ }
        {
            test===50 && <>
            
            <ShinyAvatarz open={openModalShinyAvatarz} closeModal={()=>setOpenModalShinyAvatarz(false)}/> 
            </>
        }
{ /* Ends: This is code for Modal after Minting is completed */ }   

        
        </>
    )
}


export default Counter;
