import "./TheLab.css";
import jQuery from "jquery";
import $ from "jquery";
import { useEffect } from "react";

function TheLab() {
  useEffect(() => {
    $(".show-btn1").click(function () {
      $(".tow-col_accordion-hide1").addClass("show-c");
      $(".show-btn1").addClass("hide-c");
    });
    $(".close-btn1").click(function () {
      $(".tow-col_accordion-hide1").removeClass("show-c");
      $(".show-btn1").removeClass("hide-c");
    });

    $(".show-btn2").click(function () {
      $(".tow-col_accordion-hide2").addClass("show-c");
      $(".show-btn2").addClass("hide-c");
    });
    $(".close-btn2").click(function () {
      $(".tow-col_accordion-hide2").removeClass("show-c");
      $(".show-btn2").removeClass("hide-c");
    });

    return () => {};
  }, []);

  return (
    <section className="tow-col">
      <div id="thelab"></div>
      <div className="tow-col_container">
        <div className="tow-col_left">
          <h3 className="tow-col_title">This is us:</h3>
          <p className="tow-col_text">
            <span>nftlab.berlin is an NFT service provider.&nbsp;</span>
            We create unique NFT and web3 experiences for various brands and
            companies out there. We team up with international partners to lift
            your brand into web3.
          </p>
          <div className="tow-col_accordion">
            <div className="tow-col_accordion-hide2">
              <p>
                Experience and range: By instructing us your brand will benefit
                from a growing metaverse community of NFT enthusiastic
                Avatarzzz.
                <br />
                <br />
                Dont`be shy, say{" "}
                <a href="mailto:hi@nftlab.berlin">hi@nftlab.berlin</a>
              </p>
              <p className="close-btn2">okay, I got it</p>
            </div>
            <p className="show-btn2">why hire us</p>
          </div>
        </div>

        <div className="tow-col_right">
          <h3 className="tow-col_title">And this:</h3>
          <p className="tow-col_text">
            <span className="v2">nftlab.berlin is a web3 ecosystem</span>{" "}
            governed by Avatarzzz NFT holders where we welcome future brands
            into our metaverse ecosystem. Together we will experiment the
            limitless potential of web3.
          </p>
          <div className="tow-col_accordion">
            <div className="tow-col_accordion-hide1">
              <p>
                There is more: Avatarzzz NFT holders can expect free (yeah!)
                access to future NFT projects and enjoy a toll free NFT
                marketplace.
                <br />
                <br />
                But thats not it! Stay tuned for multiple use cases created by
                this lab.
              </p>
              <p className="close-btn1">okay, I got it</p>
            </div>
            <p className="show-btn1">want more?</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TheLab;
