import './ShinyAvatarz.css';
import { useNavigate } from 'react-router-dom';
import shinyBild from './ShinyAvatarz_Assets/shiny_bild.svg';
import solana from './../Wallets/Wallet_Assets/solana.svg';
import flow from './../Wallets/Wallet_Assets/flow.svg';
import algorand from './../Wallets/Wallet_Assets/algorand.svg';
import binance from './../Wallets/Wallet_Assets/binance.svg';
import polygon from './../Wallets/Wallet_Assets/polygon.svg';

import $ from "jquery";
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import Thank from '../Thank/Thank';

function ShinyAvatarz({open, closeModal}) {

    //useSelector((state) => state.cart.itemList.pop());

    /* 
        Disable body scroll when modal is open
    */
    useEffect(() => {
        //document.body.style.position = 'fixed';
        console.log("mounted");
        $('body').addClass("modal-open");


        return () => {
            //document.body.style.position = 'static';
            console.log("unmounted");
            $('body').removeClass("modal-open"); 
        }
        
    },[])


    const navigate = useNavigate();

    function navigateToWalletsPage() {
        
        navigate('/wallets');
    }


    const [openModalThank, setopenModalThank] = useState(false);
    const {account} = useWeb3React();
    const [USER_ADDRESS, set_USER_ADDRESS] = useState({solana: "", polygon: "", flow: "", algorand: "", binance: "", ethereum: account});
    //if open ! true return null
    if(!open){
        return null;
    }

    const postDataToSever = async (e) => {
        e.preventDefault();
        const {solana, polygon, flow, algorand, binance, ethereum} = USER_ADDRESS;
        const response = await fetch(`${process.env.REACT_APP_DATABASE_LINK}/savemyshinymail`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json"
            },

            body: JSON.stringify({
                solana, polygon, flow, algorand, binance, ethereum
            })
        });

        const responseStatus = await response.json();
        console.log("The server responded with status code: ", responseStatus);
        setopenModalThank(true);
    }

    let name, value;

    const handleInput = (e) => {
       // console.log(e);
        name = e.target.name;
        value = e.target.value;
        set_USER_ADDRESS({...USER_ADDRESS, [name]:value});
    }


    return (

        <section className='shinyAvatarz overlayShinyAvatarz'>
            <div className='shinyAvatarz_container'>
                <div className='shinyAvatarz_content'>
                    <div className='shinyAvatarz_img'>
                        <img src={shinyBild} alt='shiny'/>
                    </div>
                    <p>
                        You got here just in time: To claim your free whitelist spot 
                        for Avatarzzz NFTs on any of the following chains, simply 
                        copy your wallet address into the fields below and wait for 
                        your Avatarzzz to be airdropped once available on each chain.
                    </p>
                    <div className='shinyAvatarz_adress_container'>
                        <div className='shinyAvatarz_adress shinyAvatarz_solana'>
                            <input type="text" name="solana" value={USER_ADDRESS.solana} onChange={handleInput} placeholder='your solana wallet address'/>
                            <img src={solana} alt='solana'/>
                        </div>
                        <div className='shinyAvatarz_adress shinyAvatarz_flow'>
                            <input type="text" name="flow" value={USER_ADDRESS.flow} onChange={handleInput}  placeholder='your flow wallet address' />
                            <img src={flow} alt='flow'/>
                        </div>
                        <div className='shinyAvatarz_adress shinyAvatarz_algorand'>
                            <input type="text" name="algorand" value={USER_ADDRESS.algorand} onChange={handleInput}  placeholder='your algorand wallet address'/>
                            <img src={algorand} alt='algo'/>
                        </div>
                        <div className='shinyAvatarz_adress shinyAvatarz_binance'>
                            <input type="text" name="binance" value={USER_ADDRESS.binance} onChange={handleInput}  placeholder='your binance wallet address'/>
                            <img src={binance} alt='bnb'/>
                        </div>
                        <div className='shinyAvatarz_adress shinyAvatarz_polygon'>
                            <input type="text" name="polygon" value={USER_ADDRESS.polygon} onChange={handleInput}  placeholder='your polygon wallet address'/>
                            <img src={polygon} alt='matic'/>
                        </div>
                    </div>
                    <p>
                        Take your time. You can come back at any time to this website, connect your wallet and add your addresses later. 
                    </p>
                    <div className='shinyAvatarz_btn'>
                        {/*
                            1. Close Modal Shiny Avatarz on clicking below button.
                            2. Open Modal Thank You
                            <button onClick={()=>{setopenModalThank(true); {postDataToSever();}}} className='shinyAvatarz_claim'>CLAIM SPOT ON WHITELIST NOW</button>
                        */}
                        

                        {
                            USER_ADDRESS.solana.length > 0 || USER_ADDRESS.polygon.length > 0 || USER_ADDRESS.flow.length > 0 || USER_ADDRESS.algorand.length > 0 || USER_ADDRESS.binance.length > 0 ? 
                            <>
                            <button onClick={postDataToSever} className='shinyAvatarz_claim'>CLAIM SPOT ON WHITELIST NOW</button>
                            </> 
                            : 
                            <><button onClick={postDataToSever} className='shinyAvatarz_claim dimMex'>CLAIM SPOT ON WHITELIST NOW</button> </>
                        }

                        
                        <Thank openThank={openModalThank} closeThank={()=>setopenModalThank(false)} />

                       
                        <button onClick={()=>{setopenModalThank(true)}} className='shinyAvatarz_notnow'>
                            Not now - maybe later
                        </button>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ShinyAvatarz;