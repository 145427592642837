import "./Nav.css";
import connect_wallet_icon from "./Nav_Assets/connect_wallet_icon.svg";
import logo_insta_c from "./Nav_Assets/logo_insta_c.svg";
import logo_insta_w from "./Nav_Assets/logo_insta_w.svg";
import logo_twitter_c from "./Nav_Assets/logo_twitter_c.svg";
import logo_twitter_w from "./Nav_Assets/logo_twitter_w.svg";
import logo_x from "./Nav_Assets/x_logo.svg";
import logo_x_h from "./Nav_Assets/x_mouseover.svg";
import logo_youtube_c from "./Nav_Assets/logo_youtube_c.svg";
import logo_youtube_w from "./Nav_Assets/logo_youtube_w.svg";
import logo from "./Nav_Assets/logo.gif";
import Medium1 from "./Nav_Assets/Medium1.svg";
import Medium2 from "./Nav_Assets/Medium2.svg";
import meetup_hover from "./Nav_Assets/meetup_hover.svg";
import Meetup from "./Nav_Assets/Meetup.svg";
import newsletter_gradient from "./Nav_Assets/newsletter_gradient.svg";
import newsletter_white from "./Nav_Assets/newsletter_white.svg";
import opensea from "./Nav_Assets/opensea.svg";
import opensea1 from "./Nav_Assets/opensea1.svg";
import avatarzzz_logo from "./Nav_Assets/avatarzzz_logo.svg";
import linkedin1 from "./Nav_Assets/icon_linkedin1.svg";
import linkedin2 from "./Nav_Assets/icon_linkedin2.svg";
import Chaffle from "./chaffle";
import { useWeb3React } from "@web3-react/core";
import { HashLink as Link } from "react-router-hash-link";
import { useEffect, useState } from "react";

import { ethers } from "ethers";
import { abi } from "../../abi";
import configFile from "../../configFile.json";

import { useSelector } from "react-redux";



function Nav() {
  const { active, account } = useWeb3React();
  const [num, setNum] = useState();

  /* Adding My wallet button after mint */
  let up_me = useSelector((state) => state.cart.showCart);
  //console.log("UP_ME: ", up_me);

  //getUserNftHoldings()

  async function getUserNftHoldings() {
    if (account !== undefined) {
      /* Starts: infura fix */
      const providesr = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_MAINNET
      );
      /* Starts: infura fix */

      const contractAddress = configFile.CONTRACT_ADDRESS;
      let CONTRACT = new ethers.Contract(contractAddress, abi, providesr);

      let findMyToken = account;
      const balance = await CONTRACT.balanceOf(findMyToken);
      //console.log("BAl is ", parseInt(balance._hex));
      setNum(parseInt(balance._hex));
      return parseInt(balance._hex);
    } else {
    }
  }

  useEffect(() => {
    var elements = document.querySelectorAll("[data-chaffle]");
    Array.prototype.forEach.call(elements, function (el) {
      var chaffle = new Chaffle(el);
      el.addEventListener("mouseover", function () {
        chaffle.init();
      });
    });

    return () => {};
  }, []);

  useEffect(() => {
    getUserNftHoldings();
    if ((account !== undefined) === true) {
      const ele = document.getElementById("changeMeToDisconnect");
      ele.innerHTML = `&nbsp;&nbsp;&nbsp;${account.substring(
        0,
        4
      )} ... ${account.substring(account.length - 4)}`;
    }
  }, [account, up_me]);

  return (
    <>
      {/* This is web version */}

      <nav className="nav">
        <div className="close_hamburger"></div>

        <div className="hamburger">
          <span className="span_tl"></span>
          <span></span>
          <span className="span_tr"></span>
          <span></span>
          <span></span>
          <span></span>
          <span className="span_bl"></span>
          <span></span>
          <span className="span_br"></span>
        </div>

        <div className="nav_container2">
          <div className="nav_logo">
            {/*<a href="/home"> <img className="logo" src={logo} alt="Avatarzzz NFT"></img> </a>*/}
            {/*<Link to="/home" ><img className="logo" src={logo} alt="Avatarzzz NFT"></img> </Link>*/}
            <Link to="/#top">
              <img className="logo" src={logo} alt="Avatarzzz NFT"></img>{" "}
            </Link>
          </div>

          <div className="nav_container">
            <div className="nav_left">
              <ul className="nav_list">
                <li className="nav_list_item testpunkt">
                  {/*<a href="#top"> <p className="nav_home" data-chaffle="en" data-chaffle-delay="120" >HOME</p> </a>*/}
                  <Link to="/#top">
                    {" "}
                    <p
                      className="nav_home"
                      data-chaffle="en"
                      data-chaffle-delay="120"
                    >
                      HOME
                    </p>{" "}
                  </Link>
                </li>

                <li className="nav_list_item">
                  {/*<a href="#thelab"> <p className="nav_lab" data-chaffle="en" data-chaffle-delay="120" >THE&nbsp;LAB</p> </a>*/}
                  <Link to="/#thelab">
                    <p
                      className="nav_lab"
                      data-chaffle="en"
                      data-chaffle-delay="120"
                    >
                      THE&nbsp;LAB
                    </p>{" "}
                  </Link>
                </li>

                <li className="nav_list_item">
                  <Link to="/#services">
                    {" "}
                    <p
                      className="nav_services"
                      data-chaffle="en"
                      data-chaffle-delay="120"
                    >
                      SERVICES
                    </p>{" "}
                  </Link>
                </li>

                <li className="nav_list_item">
                  <Link to="/#about">
                    {" "}
                    <p
                      className="nav_about"
                      data-chaffle="en"
                      data-chaffle-delay="120"
                    >
                      ABOUT
                    </p>{" "}
                  </Link>
                </li>

                <div className="nav_avatarzz">
                  <li className="nav_list_item nav_avatarz">
                    <Link to="/mint">
                      {" "}
                      <p
                        className="nav_avatarzzz"
                        data-chaffle="en"
                        data-chaffle-delay="120"
                      >
                        AVATARZZZ&nbsp;MINT
                      </p>{" "}
                    </Link>
                  </li>
                </div>

                <li className="nav_list_item">
                  <Link to="/#roadmap">
                    {" "}
                    <p
                      className="nav_roadmap"
                      data-chaffle="en"
                      data-chaffle-delay="120"
                    >
                      ROADMAP
                    </p>{" "}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="nav_right">
              <ul className="nav_social">
                <li className="nav_social_item nav_find-us"> More:</li>

                <li className="nav_social_item nav_social_twitter">
                  <a
                    href={"https://twitter.com/nftlab_berlin"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="icon1"
                      src={logo_x}
                      alt="Avatarzzz NFT"
                    />
                    <img
                      className="icon2"
                      src={logo_x_h}
                      alt="Avatarzzz NFT"
                    />
                  </a>
                </li>

                <li className="nav_social_item nav_social_medium">
                  <a
                    href="https://nftlabberlin.medium.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon1" src={Medium1} alt="Avatarzzz NFT" />
                    <img className="icon2" src={Medium2} alt="Avatarzzz NFT" />
                  </a>
                </li>

                <li className="nav_social_item nav_social_opensea">
                  <a
                    href="https://opensea.io/nftlabberlin/created"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon1" src={opensea} alt="Avatarzzz NFT" />
                    <img className="icon2" src={opensea1} alt="Avatarzzz NFT" />
                  </a>
                </li>

                <li className="nav_social_item nav_social_insta">
                  <a
                    href="https://www.instagram.com/nftlab.berlin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon1" src={logo_insta_w} alt="" />
                    <img className="icon2" src={logo_insta_c} alt="" />
                  </a>
                </li>

                <li className="nav_social_item nav_social_live">
                  <a
                    href="https://de.linkedin.com/in/chris-hortsch-bb4951197"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon1" src={linkedin1} alt="Avatarzzz NFT" />
                    <img className="icon2" src={linkedin2} alt="Avatarzzz NFT" />
                  </a>
                </li>

                <li className="nav_social_item nav_social_youtube">
                  <a
                    href="https://www.youtube.com/c/nftlabberlin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="icon1"
                      src={logo_youtube_w}
                      alt="Avatarzzz NFT"
                    />
                    <img
                      className="icon2"
                      src={logo_youtube_c}
                      alt="Avatarzzz NFT"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Changed this  */}
        <div className="nav_wallet wallet_desktop">
          <div className="nav_wallet_item">
            <div className="nav_wallet_item_v1">
              <img
                className="ava-icon"
                id="ava_icon"
                src={avatarzzz_logo}
                alt=""
              />

              <h3 className="ava-title" id="changeMeToDisconnect">
                MY AVATARZZZ
              </h3>
            </div>
            <div className="nav_wallet_item_v2">
              <img className="wallet-icon" src={connect_wallet_icon} alt="" />

              <Link
                to="/connectwallet"
                style={{ textDecoration: "none" }}
                className="wallet-title"
              >
                <h3 id="id_disconnect">CONNECT WALLET</h3>{" "}
              </Link>
            </div>
          </div>
          {
            //Fixes shows my wallet on account change : console.log("hfjhsvjhw ", getUserNftHoldings())
            //console.log("hfjhsvjhw ", getUserNftHoldings())
          }

          {active === true && num > 0 ? (
            <div className="walletIconLogin">
              <Link to="/Wallets">
                <img src={avatarzzz_logo} alt="nftberlinIcon" />
                <p>My Avatarzzz</p>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </nav>
      <div className="hamburger_overlay">
        <div className="hamburger_container">
          <div className="hamburger_content">
            <ul className="hamburger_list">
              <li className="hamburger_list_item">
                {/* <a href="#top0" className="nav_home"> HOME </a> */}
                <Link to="/#top" className="nav_home">
                  HOME{" "}
                </Link>
              </li>
              <li className="hamburger_list_item">
                <Link to="/#thelab" className="nav_lab">
                  THE&nbsp;LAB{" "}
                </Link>
              </li>

              <li className="hamburger_list_item">
                <Link to="/#services" className="nav_services">
                  SERVICES{" "}
                </Link>
              </li>

              <li className="hamburger_list_item">
                <Link to="/#about" className="nav_about">
                  ABOUT{" "}
                </Link>
              </li>

              <li className="hamburger_list_item  nav_list_avatarzzz">
                <Link to="/mint" className="nav_avatarzzz">
                  AVATARZZZ MINT{" "}
                </Link>
              </li>

              <li className="hamburger_list_item">
                <Link to="/#roadmap" className="nav_roadmap">
                  ROADMAP{" "}
                </Link>
              </li>

              {/* <div className="nav_wallet-mob">
                            <div className="nav_wallet_item">
                                <div className="icon">
                                    <img className="ava-icon" src={avatarzzz_logo} alt="" />
                                </div>
                                <h3 className="ava-title">MY AVATARZZZ</h3>
                            </div>
                        </div> */}

              {/* <div className="nav_wallet nav_wallet-mob">
                            <div className="nav_wallet_item">
                                <div className="nav_wallet_item_v1">
                                        
                                    <img className="ava-icon" id='ava_icon' src={avatarzzz_logo} alt="" />

                                    <h3 className="ava-title" id='changeMeToDisconnect'>MY AVATARZZZ</h3>
                                </div>
                                
                                <div className="nav_wallet_item_v2">
                                    <img className="wallet-icon" src={connect_wallet_icon} alt="" />  
                                    <Link to="/connectwallet" style={{textDecoration:'none'}} className='wallet-title'><h3 id='id_disconnect'>CONNECT WALLET</h3> </Link>
                                </div>
                                

                            </div>


                        </div>  */}

              <div className="nav_wallet nav_wallet_small">
                <div className="nav_wallet_item nav_wallet_item_connect">
                  <div className="nav_wallet_item_v1">
                    <img
                      className="ava-icon2"
                      id="ava_icon2"
                      src={avatarzzz_logo}
                      alt=""
                    />

                    <h3 className="ava-title" id="changeMeToDisconnect2">
                      MY AVATARZZZ
                    </h3>
                  </div>
                </div>

                <div className="nav_wallet_item nav_wallet_item_disconnect">
                  <div className="nav_wallet_item_v2">
                    <img
                      className="wallet-icon2"
                      src={connect_wallet_icon}
                      alt=""
                    />

                    <Link
                      to="/connectwallet"
                      style={{ textDecoration: "none" }}
                      className="wallet-title"
                    >
                      <h3 id="id_disconnect2">DISCONNECT</h3>{" "}
                    </Link>
                  </div>
                </div>
                {
                  //Fixes shows my wallet on account change : console.log("hfjhsvjhw ", getUserNftHoldings())
                  //console.log("hfjhsvjhw ", getUserNftHoldings())
                }

                {active === true && num > 0 ? (
                  <div className="walletIconLogin">
                    <Link to="/Wallets">
                      <img src={avatarzzz_logo} alt="nftberlinIcon" />
                      <p>My Avatarzzz</p>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
