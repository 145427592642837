import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',    
    initialState: {
        itemList: [], 
        totalQuantity: 0,
        showCart: false,
        saveEmailMintState: ""
    },
    reducers: {
        addToCart(state, action) {
            
            const newItem = action.payload;
            // check if the item is there. If its there do nothing. officially remove
            const existingItem = state.itemList.find(item => item.name === newItem.name);
            if(existingItem){
                console.log("ITEM EXIST")
            }
            else {
               // console.log("ITEM DOES NOT EXIST. ADDING IT TO CART");
                
                state.itemList.push({
                    name: newItem.name,
                    totalPrice: newItem.price
                })

                state.totalQuantity++;
            }
            
            // Printing the item which is requested to be added in cart.
           // console.log("Item requested to be added is : ",newItem);
        },

        removeFromCart(state, action) {
            const name = action.payload;
            const existingItem = state.itemList.find(item => item.name === name);
            if(existingItem){
                state.itemList = state.itemList.filter(item => item.name !== name);
                state.totalQuantity--;
            }
        },

        setShowCart(state) {
            state.showCart = !state.showCart;
        },

        setSaveEmailMintState(state, action) {
            const tempMail = action.payload;
            state.saveEmailMintState = tempMail;
        }
    }   
});
export const cartActions = cartSlice.actions;
export default cartSlice;
