import './About.css';

function About() {
    return(
        <section className="about">
            <div id="about"></div>
            <div className="about_container">
                <div className="about_content">
                    <h3 className="about_title">
                        about
                    </h3>
                    <p className="about_text">
                        nftlab.berlin was formed by enthusiastic NFT pioneers from Berlin Germany in 2021. Our team is backed by several web3, design, code and brand building experts from around the globe united by a simple smart contract. We strongly believe in new technologies and the power of a community driven metaverse.
                        <br /><br />
                        Founded by the team of <a href="https://www.chris-andfriends.de" target="_blank" rel="noreferrer">www.chris-andfriends.de</a>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default About;