import './Mint.css';
import { useEffect, useRef, useState } from 'react';
import Metaverse_Hintergrund from './Mint_Assets/Metaverse_Hintergrund.png';
import avatarz_video from './Mint_Assets/avatarz_video.mp4';
import eth_logo from './Mint_Assets/eth.png';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../CartRedux/cartSlice';
import { useSelector } from 'react-redux';
import Counter from '../../CartRedux/Counterx';
import { ethers } from 'ethers';
import { abi } from '../../abi';
import video_img from './Mint_Assets/video_img.png';
import solana from './Mint_Assets/solana.svg';
import flow from './Mint_Assets/flow.svg';
import algorand from './Mint_Assets/algorand.svg';
import binance from './Mint_Assets/binance.svg';
import polygon from './Mint_Assets/polygon.svg';
import $ from 'jquery';
import configFile from '../../configFile.json';

function Mint() {
    
    const [active, setActive] = useState("showAllNFT");
    const cartItems = useSelector((state) => state.cart.itemList);
    const dispatch = useDispatch();
    const myprovider = configFile.ALCHEMY_PROVIDER;
    const countTemp = useSelector((state) => state.cart.totalQuantity);

    /* Start: Show all NFT infinite scroll */
    const [visible, setVisible] = useState(10);
    const loadMore = () => {
        setVisible((prevValue)=> prevValue + 1 );
    }
    /* End: Show all NFT infinite scroll */

    /* Start: Show only available infinite scroll */
    const[visibleShowAll, setvisibleShowAll] = useState(10);
    const loadMoreOnlyAvailable = () => {
        setvisibleShowAll((prevValue)=> prevValue + 20 );
    }
    /* End: Show only available infinite scroll */
    
    const addToCart = (e) => {

        /* Start: animate cart button after item is added to cart */

        $(".animate_me").animate({ height: 160}, 150, "linear",
            function() {
                $(".animate_me").removeClass("animate_me");
                
            }

        );

        $(".btn_cart_container").addClass("animate_me", function(){
            $(".animate_me").animate({ height: 130 } );
            $(".btn_cart").css({"box-shadow": '0px 0px 50px #00fff74f' } );
        });

        /* End: animate cart button after item is added to cart */
        
        console.log(e.target.alt);
        var name = `AVATARZZZ ${e.target.alt}`;
        var price = "5 USDC"
        dispatch( cartActions.addToCart({ name, price }));
    };

    const [nftSold, setNftSold] = useState([]); 

    async function getAllSoldNft() {
        //console.log("ssss: ",nftSold);
        
        
       // const tempProvider = new ethers.providers.Web3Provider(window.ethereum); // new provider check - this is not from app.js
        const tempProvider = new ethers.providers.JsonRpcProvider(myprovider);
        
        
        
        const contractAddress = configFile.CONTRACT_ADDRESS;
        let CONTRACT = new ethers.Contract(contractAddress, abi, tempProvider);
        
        let events = await CONTRACT.queryFilter('NftMinted', 16627048, 106627048 );
        let arrayOfSoldNft = [];
       // console.log("typessssssss: ", typeof(arrayOfSoldNft));
        events.map((temp) => {
            arrayOfSoldNft.push(parseInt(temp.topics[2], 16));
        });
        /* RED FLAG  */
        try{

        
       // console.log("arrayOfSoldNft : ",arrayOfSoldNft);
        setNftSold(arrayOfSoldNft);
        soldData.current = arrayOfSoldNft;
        

        // prevState.current = arrayOfSoldNft;
        // console.log("prev statet is :::", prevState.current);

       // console.log("arrayOfSoldNfssssss: ", nftSold);
        }
        catch(e){
            console.log("Error while setting state for sold nft: ", e);
        }
       // console.log(events[4].topics[2]);
        //console.log(parseInt(events[4].topics[2], 16));

       // console.log("These are sold NFT and fetched directly from blockchain: ", nftSold); //not working?? - probably late update 
        //console.log("arrayofsoldnft: ", arrayOfSoldNft);
        return arrayOfSoldNft;
    }

    /* This is code for getting all available NFTs */

    const [AllAvailableNft, setAllAvailableNft] = useState([]);
    async function getAllAvailableNft() {
        let avaliableNFTs = num;
        let soldNFT = await getAllSoldNft();
        let temp = []

       // console.log("::::::::::::::::::::::::",soldNFT);
        soldNFT.map((temp) => {
            /* If temp exist in soldNFT array. Remove it */
            if(avaliableNFTs.includes(temp)){
                avaliableNFTs.splice(avaliableNFTs.indexOf(temp), 1);
            }
        });
        setAllAvailableNft(avaliableNFTs);
       // console.log("Avaliable NFT are as follows: ", avaliableNFTs);
    }

   /* This is code for active tab highlighting */
        
    $('document').ready(function () {
        $('.nft_btn').click(function () {
            $(this).siblings().removeClass('btn_active');
            $(this).addClass('btn_active');
        });
    });

    useEffect(()=>{
        $(".video_video").click(function(e){
            $(".video_img").addClass("displayn");
          });

    },[])

    let soldData = useRef([]);

    /* Observer Code starts */    

    useEffect(() => {
    
    //return ()=> {
        async function fun() {
            const data = await getAllSoldNft();
            return data;
        }
        let x = fun();
        x.then((value) => {

            soldData.current = value;
            setNftSold(value);

           // console.log("sssoldzzz: ", soldData.current );
            
            const cards = document.querySelectorAll('.infiniteScrollAll');
           // console.log("cards",cards);
            if(cards.length == 0){

            }
            else{

            
            const observer = new IntersectionObserver((entries) => {
                
                entries.forEach(entry => {
                    if(entry.isIntersecting){
                        /*  starts: checking for sold nft as well */
                       // console.log(entry);
                        let temp = entry.target.firstElementChild.alt;
                       // console.log("TEMP: ", temp);
                        if(soldData.current.includes(parseInt(temp))){
                            entry.target.classList.add("sold");
                            entry.target.classList.remove("available");
                        }
                        /* Ends: checking for sold nft as well */
                        observer.unobserve(entry.target);
                    }
                })
                
            });
    
            cards.forEach(card => {
                observer.observe(card);
            })


            const cardLast = document.querySelectorAll('.infiniteScrollAll');
            const lastCardObserver = new IntersectionObserver((entries) => {
                const lastCard = entries[entries.length-1];
                //console.log("last card is ",lastCard)
                if(lastCard.isIntersecting)
                {
                    document.getElementById("LoadMe").click(); // after intersecting add new nft
                    lastCardObserver.unobserve(lastCard.target); // unobserve the last one
                    const cardLast1 = document.querySelectorAll('.infiniteScrollAll'); //1.get lastest added nft
                    const latestNFT = cardLast1[cardLast1.length - 1]; //2.get lastest added nft
                    observer.observe(latestNFT);
                    lastCardObserver.observe(latestNFT);
                }
            },{});
            lastCardObserver.observe(cardLast[cardLast.length - 1]);

        

            // cards[0].addEventListener('dblclick', (e) => {
            //     console.log("doubleclik");
            // });
        }

        });

    //}

    },[active],[]);
    
    /* Observer Code Ends */

    /* Start: infinite scroll only available ones */

    useEffect(()=>{

       // return ()=> {
            const cardLast = document.querySelectorAll('.infiniteScrollAvailable');
            //console.log("CLCL: ",cardLast);

            if(cardLast.length == 0){

            }
            else
            {
                const lastCardObserver = new IntersectionObserver((entries) => {
                    const lastCard = entries[entries.length-1];
                    //console.log("last card is ",lastCard)
                    if(lastCard.isIntersecting)
                        {
                            document.getElementById("LoadMeOnlyAvailable").click(); // after intersecting add new nft
                            lastCardObserver.unobserve(lastCard.target); // unobserve the last one
                            const cardLast1 = document.querySelectorAll('.infiniteScrollAvailable'); //1.get lastest added nft
                            const latestNFT = cardLast1[cardLast1.length - 1]; //2.get lastest added nft
                            //observer.observe(latestNFT);
                            lastCardObserver.observe(latestNFT);
                        }
                    },{}); 
                    lastCardObserver.observe(cardLast[cardLast.length - 1]);
            }

            
       // }

    },[AllAvailableNft, active]);

    /* Ends: */

    function videoMe() {
        console.log("hello video");
    }

    return(
        <>
        <section className="video">
            <div className="video_bg">
                <img src={Metaverse_Hintergrund} alt="" onClick={videoMe}/>
            </div>
            <div className="video_container">
                <div className="video_video">
                    <div className="video1">
                    <div className="video_img">
                            <img src={video_img} alt="" />
                            <div className="playv"></div>
                    </div>
                        <video src={avatarz_video} type="video/mp4" controls></video>
                    </div> 
                </div>
            </div>
        </section>

        <section className="tow-col">
        <div id="thelab"></div>
        <div className="tow-col_container">
            <div className="tow-col_left">
                <p className="tow-col_text">
                    <span>Our mission is </span> 
                    to create a web3 ecosystem for Avatarzzz NFT holders and welcome future brands into our metaverse ecosystem. Lets experiment the limitless potential of web3. NFT holders can expect free (yeah!) access to future NFT projects, including those for 3rd party brands, a toll free NFT marketplace and multiple other use cases.
                </p>

            </div>

            <div className="tow-col_right">
                <p className="tow-col_text">
                    <span className="v2">Avatarzzz is </span> 
                    a limited NFT collection of 10.000 unique ai generated NFTs, living on the Ethereum blockchain. There is more: Avatarzzz non fungible token equivalents will appear on fellow major blockchains such as solana, polygon, flow and other chains. Why wait?! Lets get started. First we take Berlin.
                </p>
            </div>
        </div>
        </section>

        <section className="avatarz">
            <div className="avatarz_container">
                <h3 className="avatarz_title">
                    avatarzzz
                </h3>

                <div className="avatarz_grid">
                    <div className="card">
                        <h4 className="card_title">
                            BLOCKCHAIN
                        </h4>
                        <div className="card_content">
                            <img className='eth_logo' src={eth_logo} alt="" />
                        </div>
                    </div>

                    <div className="card">
                        <h4 className="card_title">
                            PRICE/NFT
                        </h4>
                        <div className="card_content">
                            <p>
                                $10 
                            </p>
                        </div>
                    </div>

                    <div className="card">
                        <h4 className="card_title">
                            UTILITIES
                        </h4>
                        <div className="card_content">
                           <a href='https://nftlabberlin.medium.com/avatarzzz-nft-holders-lets-talk-about-shiny-utilities-ec8189959a9d' target="_blank" rel="noopener noreferrer"> <p>
                                Click here
                            </p></a>
                        </div>
                    </div>

                    <div className="card">
                        <h4 className="card_title">
                            MAX/WALLET
                        </h4>
                        <div className="card_content">
                            <p>
                                1 NFT
                            </p>
                        </div>
                    </div>

                    <div className="card">
                        <h4 className="card_title">
                            WHITELIST FOR
                        </h4>
                        <div className="card_content">
                            <div className="b-logo">
                                <img src={solana} alt="" />
                            </div>
                            <div className="b-logo">
                                <img src={flow} alt="" />
                            </div>
                            <div className="b-logo">
                                <img src={algorand} alt="" />
                            </div>
                            <div className="b-logo">
                                <img src={binance} alt="" />
                            </div>
                            <div className="b-logo">
                                <img src={polygon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <p className="avatarz_text">
                    Already have an Avatarzzz NFT? Click on My Avatarazzz to connect your wallet and add/change your whitelist wallet address for fellow blockchains airdrops.
                </p>

                <p className='avatarz_text ShowcontractAddress'>
                    Avatarzzz verified smart contract address: <a href={`https://etherscan.io/address/${configFile.CONTRACT_ADDRESS}`} target={'_blank'}>{configFile.CONTRACT_ADDRESS}</a>
                </p>
            </div>
        </section>

        <section>
        <div className='nft_button'>
            {/* include sold and unsold both. for sold give sold tag and dark. Select option appears once mouse is pointed on available nft*/}
            {/* Select nft -> cart (max buy 1 and unlimited cart)*/}
            {/* max mint 1 per address*/}
            <button className='nft_btn btn_active' onClick={()=> {
                setActive("showAllNFT");
                }}>Show all NFTs</button>
            
            
            <button className='nft_btn' onClick={()=> {
                setActive("showAvailableNFT")
                getAllAvailableNft()
            }}>Show available NFTs</button>
            
            <button  className='nft_btn' onClick={()=> {
                setActive("showSoldNFT")
                getAllSoldNft()
            }}>Show Minted NFTs</button>
            
        </div> 
        
        <div className='nfts_container'>
            { 
                active==='showAllNFT' && num1.slice(0, visible).map((temp) => {
                    return  <div className='available infiniteScrollAll' key={`${temp}`} >
                        {/* <img src={`https://gateway.pinata.cloud/ipfs/QmVpjwXJYjhLtUkNzBZvFSf52FESgqHY1eBcmzZi8Y5DPW/${temp}.png`} width="250px" height="250px" alt={`${temp}`} onClick={addToCart} />  */}
                        <img src={`https://storage.googleapis.com/ethnft_compress/${temp}.jpg`} width="250px" height="250px" alt={`${temp}`} onClick={addToCart} /> 
                        <button >select nft</button>
                </div>    
            }) 
            }
           <button id="LoadMe"onClick={loadMore}>Load More</button>
           <button id="LoadMeOnlyAvailable"onClick={loadMoreOnlyAvailable}>Load More Only Available</button>
        </div>
        
        <div className='nfts_container'>
            { 
                active==='showAvailableNFT' && AllAvailableNft.slice(0, visibleShowAll).map((temp) => {
                    return  <div className='available infiniteScrollAvailable' key={`${temp}`} >
                        {/*<img src={`https://gateway.pinata.cloud/ipfs/QmVpjwXJYjhLtUkNzBZvFSf52FESgqHY1eBcmzZi8Y5DPW/${temp}.png`} width="250px" height="250px" alt={`${temp}` } onClick={addToCart} /> */}
                        <img src={`https://storage.googleapis.com/ethnft_compress/${temp}.jpg`} width="250px" height="250px" alt={`${temp}` } onClick={addToCart} /> 
                        <button>select nft</button>
                </div>    
            }) 
            }
        </div>



        <div className='nfts_container'>
            { 
                active==='showSoldNFT' && nftSold.map((temp) => {
                    return  <div className='sold' key={`${temp}`} >
                        {/* <img src={`https://gateway.pinata.cloud/ipfs/QmVpjwXJYjhLtUkNzBZvFSf52FESgqHY1eBcmzZi8Y5DPW/${temp}.png`} width="250px" height="250px" alt={`${temp}`} />  */}
                        <img src={`https://storage.googleapis.com/ethnft_compress/${temp}.jpg`} width="250px" height="250px" alt={`${temp}`} /> 
                </div>    
            }) 
            }
        </div>

        </section>

        <section className='cart_section'>
            {/* if cartItems.length > 1 show button*/}
            {
                (cartItems.length > 0) && 
                <>
               {/* <button className='btn_cart' onClick={showCart}>VIEW SELECTION</button> */}
                <Counter/>
                </>
            }
            
        </section>

        
        </>
    );
}

const num = [...Array(10000).keys()];
const num1 = [...Array(10000).keys()];

export default Mint;

/*
<button onClick={()=> setActive("showSoldNFT")}>Show sold NFTs</button>
*/

/* 
Infinite scrolling -> interaction observer


*/

