export default[
    {
        id: 1,
        question: "So how do you feel today?",
        options: [
            './Engagement_Assets/q1_op1.png',
            './Engagement_Assets/q1_op2.png'
        ]
    },

    {
        id: 2,
        question: "Pick your favourite number:",
        options: [
            '1980',
            '2004',
            '1959',
            '1993',
            '2018',
            '1977'
        ]
    },

    {
        id: 3,
        question: "Pick a color",
        options: [
            './Engagement_Assets/q2_op1.png',
            './Engagement_Assets/q2_op2.png'
        ]
    },

    {
        id: 4,
        question: "What are you into ?(max 3):",
        options: [
            'Fashion',
            'Sport',
            'Technology',
            'Mobility',
            'Events',
            'Music',
            'Food/Drinks',
            'Travels',
            'Finance',
            'Gaming'
        ]
    },

    {
        id: 5,
        question: "What does the future sound like?",
        options: [
            '/Engagement_Assets/q5_op1.mp3',
            '/Engagement_Assets/q5_op2.mp3',
        ]
    },

    {
        id: 6,
        question: "My Avatarzzz NFT should:",
        options: [
            'Become and expensive NFT to sell',
            'Give lifelong access to exclusivity'
        ]
    },

    {
        id: 7,
        question: "What language do you understand?",
        options: [
            'English',
            'Spanish',
            'German',
            'Hindi',
            'French',
            'Japanese',
            'Chinese',
            'Korean',
            'Arabic',
            'Russian',
            'Portugese',
            'Bengali'
        ]
    },

    {
        id: 8,
        question: "Whats your fav Blockchain?(Max 3)",
        options: [
            'Ethereum',
            'Solana',
            'Flow',
            'Polygon',
            'Algorand',
            'Binance',
            'Tezos',
            'Avalanche'
        ]
    },

    {
        id: 9,
        question: "You are more than just a little ahead of your time!",
        options: [
            'You’re one out of 10.000 Avatarzzz who helped shaping and designing a future metaverse with your creative mind. And this is just the beginning: The team behind newtopia.xyz is building what’s next in web3 and beyond. Expect viral designs, 3D collectibles, exclusives wearables, unique avatars and original experiences on multiple blockchains. You got here just in time.',
            '/// We recommend to: ',
            'Connect your NFT to other blockchains',
            'No thanks. Maybe later.'
        ]
    }
]