import './Roadmap.css';

function Roadmap() {
    return(
        <section className="roadmap">
            <div id="roadmap"></div>
            <div className="roadmap_container">
                <h2 className="roadmap_title">
                    ROADMAP
                </h2>

                <div className="roadmap_bg">
                </div>

                <div className="roadmap_phase">
                    <div className="roadmap_phase_step">
                        <h5>
                            Preparation period
                        </h5>
                        <p>
                            Launching the nftlab.berlin website, its brand identity, teaming up with international NFT experts, building a community by educational work
                        </p>
                    </div>
                    <div className="roadmap_phase_step">
                        <h5>
                            Bringing 10.000 unique Avatarzzz to life
                        </h5>
                        <p>
                            Designing, coding and bringing 10.000 unique NFTs 
                            on a sustainable blockchain requires some effort. We’re here for the long haul.
                        </p>
                    </div>
                    <div className="roadmap_phase_step">
                        <h5>
                            Finding a home for all Avatarzzz NFTs
                        </h5>
                        <p>
                            By launching the INO (initial NFT offering) 
                            nftlab.berlin will sell 10.000 NFTs on the 
                            Ethereum Blockhain.
                        </p>
                    </div>
                    <div className="roadmap_phase_step">
                        <h5>
                            Lets go cross chain
                        </h5>
                        <p className="padd">
                            Expect Avatarzzz NFT equivalents to appear on all major fellow blockchains such as solana, polygon or flow. Free for Ethereum Avatarzzz NFT holders.
                        </p>
                    </div>
                    <div className="roadmap_phase_step">
                        <h5 className="roadmap_phase_subtitle">
                            Welcome to newtopia.xyz
                        </h5>
                        <p>
                            Launching a creator friendly NFT marketplace, where Avatarzzz NFT holders always trade for free. 
                        </p>
                    </div>

                    <div className="roadmap_phase_step">

                        <h5>
                            Entering the market
                        </h5>
                        <p>
                            nftlab.berlin officially launches its services to 
                            all kind of brands and companies out there.
                        </p>
                    </div>

                    <div className="roadmap_phase_step roadmap_phase_step_withTitle">
            
                        <h5 >
                            Launching Avatarzzz Coin 
                        </h5>
                        <p className="roadmap_phase_gra">
                            In order to support local economies [A] Coin is made to support what’s next, controlled and built by the Avatarzzz community. First we take Berlin.
                        </p>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default Roadmap;