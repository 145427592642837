import "./ShinyTicket.css";
import logoV1 from "./ShinyTicket_Assets/logo_nftlab_berlin.png";
import logoAva from "./ShinyTicket_Assets/AVA_logo.png";
import ticketVideo from "./ShinyTicket_Assets/shinyticket_min.mp4";
import ticketVideoWebm from "./ShinyTicket_Assets/shinyticket.webm";
import { useEffect } from "react";
import $ from "jquery";


function ShinyTicket() {

    useEffect(()=>{

        if(window.location.pathname === '/shinyticket') {
                        
            $(".nav").remove();
            $(".footer").remove();
            $(".hamburger_overlay").remove();
        }

    },[]);


  return (
    <div className="center full-sized main">
      <section className="logos">
        <div className="flex-row-center logo-container">
          <img className="nft" src={logoV1} alt='' />
          <img className="nft" src={logoAva} alt='' />
        </div>
      </section>
      <section className="content">
        <div className="content__container">
          <h1>Avatarzzzz community ticket</h1>
          <p>
            Created by:&nbsp;
            <a href="/" target="_blank" rel="noopener noreferrer">
              nftlab.berlin
            </a>
            &nbsp;
            /// Ticket type: Event Ticket /// Total supply: 200
          </p>
          <p>
            Free [yeah!] airdrop for the first 200 &nbsp;
            <a href="/mint" target="_blank" rel="noopener noreferrer">
              Avatarzzz NFT
            </a>
            &nbsp;
            holders ❤️✨
          </p>
        </div>
      </section>
      <section className="center videoTicket">
        <div className="video-container">
          <video preload="auto" autoplay playsInline muted loop autoPlay>
            <source src={ticketVideo} type="video/mp4" />
            <source src={ticketVideoWebm} type="video/webm" />
             Your browser does not support the video formats.
          </video>

        </div>
      </section>
      <section className="widget">
        <div className="center flex widget__container">
          <div className="widget__content">
          <iframe src="https://mint.webmint.io/nft-berlin" width="700" height="670" class="border:0;"></iframe>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ShinyTicket;
