import { useWeb3React } from '@web3-react/core';
import './NoAvatarz.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { abi } from '../../abi';
import { ethers } from 'ethers';
import configFile from '../../configFile.json';
import { useNavigate } from 'react-router-dom';

function NoAvatarz() {
   
    const { account } = useWeb3React();   
    const navigate = useNavigate();
    useEffect(() => { 
        if((account!==undefined)===true) {
            const ele = document.getElementById('changeMeToDisconnect');
            ele.innerHTML = `&nbsp;&nbsp;&nbsp;${account.substring(0, 4)} ... ${account.substring(account.length - 4)}`;
        }
        getUserNftHoldings();
    }, [account])

    async function getUserNftHoldings(){

        const providesr = new ethers.providers.InfuraProvider('homestead', process.env.REACT_APP_INFURA_API);
        
        const contractAddress = configFile.CONTRACT_ADDRESS;
        let CONTRACT = new ethers.Contract(contractAddress, abi, providesr);

        let findMyToken = account;
        const balance = await CONTRACT.balanceOf(findMyToken);
  
        if(balance == 0) {
            console.log("NO NFT FOUND FOR YOUR ADDRESS");
            //navigate('/noavatarz')
        }
        else {
            navigate('/Wallets')
        }
    }


    return(
        <section className="noAvatarz">
            <div className="noAvatarz_container">
                <div className="noAvatarz_content">
                    <h1>
                        NO AVATARZZZ
                    </h1>

                    {/* check if Account hold AVATARZZZ and if not redirect page accordingly */}
                    <p>
                        No Avatarzzz NFTs found for {account}
                    </p>
                    <div className="noAvatarz_btn">
                        <Link to="/mint" >GET YOUR AVATARZZZ NFT HERE</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default NoAvatarz;