import './Header.css'; 
import { useEffect } from 'react';

function Header() {

    useEffect(() => {
    
        const carouselWrap = document.querySelectorAll('.carousel-wrap');
            //console.log("cwrap: ", carouselWrap);
            for (var i=0,l=carouselWrap.length;i<l;i++) {
                carouselWrap[i].appendChild(carouselWrap[i].firstChild.cloneNode(true));
            }
    
            const carouselWrap2 = document.querySelectorAll('.carousel-wrap2');
            for (var z=0,p=carouselWrap2.length;z<p;z++) {
                carouselWrap2[z].appendChild(carouselWrap2[z].firstChild.cloneNode(true));
            }

        return () => {
        }
            
    }, [])

    return(
        <>
        <header className="header" id='top'>
            <div className="header_container">
                <div className="header_content">
                
                    <h2 className="header_title">
                        WELCOME TO
                        <div className="t2">
                            <span>NFTLAB</span><div className="header_title_img"></div>
                        </div>
                    </h2>
                    
                    <div className="header_text">
                        <h2 className="header_subtitle">
                            A community driven NFT Lab that lifts your brand into web3
                        </h2>
                        <span>Join us by owning an Avatarzzz NFT /// Its like owning <h1>NFT Lab Berlin</h1> shares - but in cool</span>
                    </div>
                
                </div>
            </div>
        
            <div className="header_sl" >

                <div className="carousel-wrap2" id="carousel-wrap2">
                    <div className="carousel-loop2" id="carousel-loop2">
                        <div className="column">
                        { 
                            num1.map((temp) => {
                                return  <div className="carousel-avatar"  key={`${temp}`}>
                                 <img src={`Header_Assets/${temp}.jpg`} alt="Avatarzzz NFT" /> 
                                </div>         
                            }) 
                        }
                        </div>
                    </div>
                </div>

                <div className="carousel-wrap" id="carousel-wrap">
                    <div className="carousel-loop" id="carousel-loop">
                        <div className="column">
                        { 
                            num2.map((temp) => {
                                return  <div className="carousel-avatar" key={`${temp}`}>
                                 <img src={`Header_Assets/${temp}.jpg`} alt="Avatarzzz NFT" /> 
                                </div>         
                            }) 
                        }
                        </div>
                    </div>
                </div>

                <div className="carousel-wrap2" id="carousel-wrap2">
                    <div className="carousel-loop2" id="carousel-loop2">
                        <div className="column">
                        { 
                            num3.map((temp) => {
                                return  <div className="carousel-avatar" key={`${temp}`}>
                                 <img src={`Header_Assets/${temp}.jpg`} alt="Avatarzzz NFT" /> 
                                </div>         
                            }) 
                        }
                        </div>
                    </div>
                </div>

                <div className="carousel-wrap" id="carousel-wrap">
                    <div className="carousel-loop" id="carousel-loop">
                        <div className="column">
                        { 
                            num4.map((temp) => {
                                return  <div className="carousel-avatar" key={`${temp}`}>
                                 <img src={`Header_Assets/${temp}.jpg`} alt="Avatarzzz NFT" /> 
                                </div>         
                            }) 
                        }
                        </div>
                    </div>
                </div>

                <div className="carousel-wrap2" id="carousel-wrap2">
                    <div className="carousel-loop2" id="carousel-loop2">
                        <div className="column">
                        { 
                            num5.map((temp) => {
                                return  <div className="carousel-avatar" key={`${temp}`}>
                                 <img src={`Header_Assets/${temp}.jpg`} alt="Avatarzzz NFT" /> 
                                </div>         
                            }) 
                        }
                        </div>
                    </div>
                </div>

                <div className="carousel-wrap" id="carousel-wrap">
                    <div className="carousel-loop" id="carousel-loop">
                        <div className="column">
                        { 
                            num6.map((temp) => {
                                return  <div className="carousel-avatar" key={`${temp}`} >
                                 <img src={`Header_Assets/${temp}.jpg`} alt="Avatarzzz NFT" /> 
                                </div>         
                            }) 
                        }
                        </div>
                    </div>
                </div>
                
            </div>
                        
        </header> 
    
        </>
    );

}

const num1 = [...Array(20).keys()];
const num2 = Array.from({length: 20}, (_, i) => i + 20);
const num3 = Array.from({length: 20}, (_, i) => i + 40);
const num4 = Array.from({length: 20}, (_, i) => i + 60);
const num5 = Array.from({length: 20}, (_, i) => i + 80);
const num6 = Array.from({length: 20}, (_, i) => i + 100);

export default Header;
