import './Thank.css';
import thank from './Thank_Assets/thank_bild.svg';
import twitter from './../Nav/Nav_Assets/logo_twitter_w.svg';
import insta from './../Nav/Nav_Assets/logo_insta_w.svg';
import medium from './../Nav/Nav_Assets/Medium1.svg';
import youtube from './../Nav/Nav_Assets/logo_youtube_w.svg';
import meetup from './../Nav/Nav_Assets/Meetup.svg';
import telegram from './Thank_Assets/telegram_icon.svg';
import { useNavigate } from 'react-router-dom';


import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../CartRedux/cartSlice";
import { useEffect } from 'react';


function Thank({openThank, closeThank}) {



    const dispatch = useDispatch();
    var items = useSelector((state) => state.cart.itemList);
    //dispatch(cartActions.removeFromCart(items[0].name));


    const navigate = useNavigate();

    function navigateToWalletsPage() {
        /* Remove item from cart: Minting completed  */
        dispatch(cartActions.removeFromCart(items[0].name));

        /* Navigate to wallet page after Thank You  */
        navigate('/wallets');
    }

    if(!openThank){
        return null;
    }



    return(

        <section className='thank modal'>
            <div className='thank_container'>
                <div className='thank_content'>
                    <div className='thank_img'>
                        <img src={thank} alt='thank'/>
                    </div>
                    <p>
                        Follow nftlab.berlin for news and updates regarding future airdrops, cooperations, use cases and more around your Avatarazzz NFT
                    </p>
                    <div className='thank_social_container'>
                        <div className='thank_social_item'>
                            <a href='https://twitter.com/nftlab_berlin' target='_blank' rel="noreferrer">
                                <img src={twitter} alt='twitter'/>
                                twitter.com/nftlab_berlin
                            </a>
                        </div>

                        <div className='thank_social_item'>
                            <a href='https://www.instagram.com/nftlab.berlin/' target='_blank' rel="noreferrer">
                                <img src={insta} alt='instagram'/>
                                @nftlab.berlin
                            </a>
                        </div>

                        <div className='thank_social_item'>
                            <a style={{textDecoration: 'underline'}} href='https://t.me/+OxTZZHbtp5VhOTQy' target='_blank' rel="noreferrer">
                                <img src={telegram} alt='telegram'/>
                               Join Telegram Group
                            </a>
                        </div>

                        <div className='thank_social_item'>
                            <a href='https://nftlabberlin.medium.com/' target='_blank' rel="noreferrer">
                                <img src={medium} alt='medium'/>
                                nftlabberlin.medium.comn
                            </a>
                        </div>

                        <div className='thank_social_item'>
                            <a href='https://www.youtube.com/c/nftlabberlin' target='_blank' rel="noreferrer">
                                <img src={youtube} alt='youtube'/>
                                youtube.com/nftlabberlin
                            </a>
                        </div>

                        <div className='thank_social_item'>
                            <a href='https://www.meetup.com/de-DE/nftlabberlin/' target='_blank' rel="noreferrer">
                                <img src={meetup} alt='meetup'/>
                                meetup.com/nftlabberlin
                            </a>
                        </div>
                    </div>        
                    <button className="highlightMe" onClick={navigateToWalletsPage}>close this page</button>
                </div>
            </div>
        </section>
    );
}

export default Thank;