import Qtion from "./Qtion";
import './Qtion.css';
import { useEffect, useRef } from "react";

function Questions(props) {

    const answer0 = useRef("");
    const answer1 = useRef(new Array());
    const answer2 = useRef("");
    const answer3 = useRef(new Array());
    const answer4 = useRef("");
    const answer5 = useRef("");
    const answer6 = useRef(new Array());
    const answer7 = useRef(new Array());

    function userSelection(e) {
        if(e.target.alt) {
            
            if(e.target.alt==="question_0") {
                console.log("q0 answer: ", e.target.id);
                answer0.current = e.target.id;
            }

            if(e.target.alt==="question_2") {
                console.log("q2 answer: ", e.target.id);
                answer2.current = e.target.id;
            }
        }
        else 
        {
            console.log("ID: ", e.target);
            
            if(e.target.id === "question_1")
            {

                if(answer1.current.includes(e.target.innerHTML)) {
                    let tempIndex = answer1.current.indexOf(e.target.innerHTML);
                    let tempArray = [];

                    for(let i=0; i < answer1.current.length; i++) {
                        if(i==tempIndex) {
                        }
                        else 
                        {
                            tempArray.push(answer1.current[i]);
                        }
                    }
                    answer1.current = tempArray;
                }
                else
                {
                    if(answer1.current.length === 3)
                    {
                        console.log("MAX Selection 3")
                    }
                    else
                    {
                        answer1.current.push(e.target.innerHTML);
                    }
                } 

            }

            if(e.target.id === "question_3")
            {
                //answer3.current = e.target.innerHTML;

                if(e.target.id === "question_3")
                {

                    if(answer3.current.includes(e.target.innerHTML)) {
                        let tempIndex = answer3.current.indexOf(e.target.innerHTML);
                        let tempArray = [];

                        for(let i=0; i < answer3.current.length; i++) {
                            if(i==tempIndex) {
                            }
                            else 
                            {
                                tempArray.push(answer3.current[i]);
                            }
                        }
                        answer3.current = tempArray;
                    }
                    else
                    {
                        if(answer3.current.length === 4)
                        {
                            console.log("MAX Selection 4")
                        }
                        else
                        {
                            answer3.current.push(e.target.innerHTML);
                        }
                    } 
                }
            }  

            if(e.target.id === "question_4")
            {
                answer4.current = e.target.innerHTML;
            }

            if(e.target.id === "question_5")
            {
                answer5.current = e.target.innerHTML;
            }

            if(e.target.id === "question_6")
            {
                //answer6.current = e.target.innerHTML;
                if(e.target.id === "question_6")
                {

                    if(answer6.current.includes(e.target.innerHTML)) {
                        let tempIndex = answer6.current.indexOf(e.target.innerHTML);
                        let tempArray = [];

                        for(let i=0; i < answer6.current.length; i++) {
                            if(i==tempIndex) {
                            }
                            else 
                            {
                                tempArray.push(answer6.current[i]);
                            }
                        }
                        answer6.current = tempArray;
                    }
                    else
                    {
                        if(answer6.current.length === 8)
                        {
                            console.log("MAX Selection 8")
                        }
                        else
                        {
                            answer6.current.push(e.target.innerHTML);
                        }
                    } 
                }
            }

            if(e.target.id === "question_7")
            {
                //answer7.current = e.target.innerHTML;

                if(e.target.id === "question_7")
                {

                    if(answer7.current.includes(e.target.innerHTML)) {
                        let tempIndex = answer7.current.indexOf(e.target.innerHTML);
                        let tempArray = [];

                        for(let i=0; i < answer7.current.length; i++) {
                            if(i==tempIndex) {
                            }
                            else 
                            {
                                tempArray.push(answer7.current[i]);
                            }
                        }
                        answer7.current = tempArray;
                    }
                    else
                    {
                        if(answer7.current.length === 3)
                        {
                            console.log("MAX Selection 3")
                        }
                        else
                        {
                            answer7.current.push(e.target.innerHTML);
                        }
                    } 
                }
            }
        }

    }


    async function sendToDatabase () 
    {
        
        const response = await fetch(`http://localhost:8006/engagementdata`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json"
            },

            body: JSON.stringify({
                answer0, answer1, answer2, answer3, answer4, answer5, answer6, answer7
            })
        });


    }

    useEffect(() => {

        console.log(" use effect ");

        if(props.questionNumber+1 === 9){
            sendToDatabase();
        }
                

    }, [props.questionNumber+1 === 9])

    return(
        <div className="questions">
            {
                props.questionNumber+1 === 9 ? 
                <>

                    <p>{Qtion[props.questionNumber].question}</p>
                    <p>{Qtion[props.questionNumber].options[0]}</p>
                    <p>{Qtion[props.questionNumber].options[1]}</p>

                    <button>{Qtion[props.questionNumber].options[2]}</button>
                    <button>{Qtion[props.questionNumber].options[3]}</button>
                </> 
                
                : 
                <>
                    <p id="questionNumber">{`${props.questionNumber+1}/8`}</p> 
                    <p id="whatsTheQuestion">{Qtion[props.questionNumber].question}</p>

                    <div >
                        <div className="qt" key={Qtion[props.questionNumber].id}>
                            {
                                Qtion[props.questionNumber].options.map((q, i) => (
                                    <div key={i}>
                                        <div value={false} name="options" id={`q${i}-option`} />
                                        {
                                            (q[0]==='.' && q[0]!=='/') ? 
                                            
                                            <img src={require(`${q}`)} alt={`question_${props.questionNumber}`} width="100" height="100" id={`question_${props.questionNumber}_choice_${i}`} onClick={userSelection}/> 
                                            : 
                                            <p id={`question_${props.questionNumber}`} onClick={userSelection}>{q}</p>

                                        }
                                        {/*
                                            (q[0]==='/') ? 
                                            <audio controls autoplay muted src="horse.ogg" type="audio/ogg"> </audio>

                                            : 
                                            
                                            <></> 
                                            */
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default Questions;