import './Wallet.css';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import { useLocation, useNavigate } from 'react-router-dom';
import { abi } from '../../abi';
import { ethers } from 'ethers';
import { useState , useEffect} from 'react';
import configFile from '../../configFile.json';

function Wallet() {
   
    const Loaction = useLocation();

    const web3reactContext = useWeb3React();
    const {activate ,account, active, library: provider} = useWeb3React(); 

    const navigate = useNavigate();
    const injected = new InjectedConnector({
        supportedChainIds: [1]
    });  


    const WalletConnect = new WalletConnectConnector({
        rpc:{
          // 1: process.env.REACT_APP_ALCHEMY_MAINNET,
          1: process.env.REACT_APP_INFURA_MAINNET,
        },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 15000,
        chainId: 1,
        infuraId: process.env.REACT_APP_INFURA_API,
        //version: '2',
    });

 

    const [nftHolding, setNftHolding] = useState(100); 

    useEffect(() => {
        //console.log("Hello, account channged to: ", account);
        if((account!==undefined)===true) {
            const ele = document.getElementById('changeMeToDisconnect');
            ele.innerHTML = `&nbsp;&nbsp;&nbsp;${account.substring(0, 4)} ... ${account.slice(account.length - 4)}`;

            const ele2 = document.getElementById('changeMeToDisconnect2');
            ele2.innerHTML = `${account.substring(0, 4)} ... ${account.slice(account.length - 4)}`;

            const disconnect = document.getElementById('id_disconnect');
            disconnect.innerHTML = 'DISCONNECT';

            const disconnect2 = document.getElementById('id_disconnect2');
            disconnect2.innerHTML = 'DISCONNECT';

            /* 1.1
            We are trying to remove Avatar icon and wallet icon once connected and putting red and green icon
            1. DCN- remove class name -> ava-icon
            2. let temp = document.getElementByClassName("nav_wallet_item_v1");
            3. temp.add
            */

            let addNewClassToAvaIcon = document.querySelector('.ava-icon');
            addNewClassToAvaIcon.classList.add("hideAvaIcon");

            let addNewClassToWalletIcon = document.querySelector('.wallet-icon');
            addNewClassToWalletIcon.classList.add("hideAvaIcon")

            let addNewClassToAvaIcon2 = document.querySelector('.ava-icon2');
            addNewClassToAvaIcon2.classList.add("hideAvaIcon");

            let addNewClassToWalletIcon2 = document.querySelector('.wallet-icon2');
            addNewClassToWalletIcon2.classList.add("hideAvaIcon")
            
            let addNewClassToWalletContainer = document.querySelector('.nav_wallet');
            addNewClassToWalletContainer.classList.add('walletConnected');

            let addNewClassToWalletContainer2 = document.querySelector('.nav_wallet_small');
            addNewClassToWalletContainer2.classList.add('walletConnected');


            disconnect.addEventListener('click', 
                function() { 
                    web3reactContext.deactivate();
                }, 
                false
            );

            disconnect2.addEventListener('click', 
            function() { 
                web3reactContext.deactivate();
            }, 
            false
        );

            getUserProfileDetails(); // Fix for undefined account
        }
        else {
            const ele = document.getElementById('changeMeToDisconnect');
            ele.innerHTML = 'MY AVATARZZZ';

            const ele2 = document.getElementById('changeMeToDisconnect2');
            ele2.innerHTML = 'MY AVATARZZZ';

            const disconnect = document.getElementById('id_disconnect');
            disconnect.innerHTML = 'CONNECT WALLET';

            const disconnect2 = document.getElementById('id_disconnect2');


            // adding icons back 1.1
            (document.querySelector('.ava-icon')).classList.remove('hideAvaIcon');
            (document.querySelector('.wallet-icon')).classList.remove('hideAvaIcon');
            (document.querySelector('.ava-icon2')).classList.remove('hideAvaIcon');
            (document.querySelector('.wallet-icon2')).classList.remove('hideAvaIcon');

            (document.querySelector('.nav_wallet')).classList.remove('walletConnected');
            (document.querySelector('.nav_wallet_small')).classList.remove('walletConnected');

        }
    },[account])

   
    /* 
        Logic for connecting to Metamask wallet
    */
    async function connect() {
        try {
           
            // await activate(injected);  
            // console.log("conected to: ", web3reactContext.account);

            await activate(injected, (e)=>{
                console.log("HELLO ERROR", e);}).then(()=>{
                    console.log("conected to: ", web3reactContext.account);        
                }
            );  

           // await getUserProfileDetails();   
        }
        catch(e) {
            console.log("There is error while connecting to metamask", e);
            console.log("Is Active: ", active);
        }
    }
    /* WalletConnect connect */
    async function wallConnect() {
        try {
            await activate(WalletConnect, (e)=>{
                console.log("HELLO ERROR", e);}).then(()=>{
                    console.log("conected to: ", web3reactContext.account);        
                }
            );  
            //await getUserProfileDetails();   
        }
        catch(e) {
            console.log("There is error while connecting to metamask", e); 
        }
    }
    
    async function getUserProfileDetails() {
        
        const contractAddress = configFile.CONTRACT_ADDRESS;
        
     //  let CONTRACT = new ethers.Contract(contractAddress, abi, provider);

        /* Starts: Infura testing */
        //console.log("Account infura : ", account);
        const providesr = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_INFURA_MAINNET);
        let CONTRACT = new ethers.Contract(contractAddress, abi, providesr);
        /* Ends: Infura testing */

        
        console.log("Account: ", account);
        console.log("Number of NFT holdings: ", await CONTRACT.balanceOf(account));

        const temp = await CONTRACT.balanceOf(account);
        //console.log("FILTER 2: ", CONTRACT.filters.Transfer(null, account));
        console.log("temp", parseInt(temp._hex, 16))
        setNftHolding(parseInt(temp._hex, 16));
        //console.log("Total NFT the above address is holdig is: ",nftHolding); // state is not managed - error

       console.log("Total NFT minted is: ", await CONTRACT._tokenIdCounter);

    }


const navigateMeToMintPage = () => navigate('/mint', { state: {prev_route: "startmintRoute"} });


    return(
        <section className="connect">
            <div className="connect_container">
                <div className="connect_content">
                    <h1>
                        CONNECT YOUR WALLET 
                    </h1>
                    {/*console.log("statesx", Loaction.state)*/}
                    <p>
                        You need to connect your wallet before proceeding.
                    </p>

                    <div className="connect_btn">
                        <button onClick={() => connect()}>METAMASK [Desktop]</button>
                        <button onClick={() => wallConnect()}>WALLETCONNECT [Mobile]</button>
                        

                        {/*<button onClick={disconect}>DISCONNECT</button>*/}

                        {/* If the wallet is connected and active 
                        1.check if the address hold nft or not 
                        2.navigate accordingly
                        */}

                        {
                            //console.log("NFT HOLDING: ", nftHolding)
                            /*  problem: nftholding data gets lost on refresh as it is set to 0.
                                solution: ?
                            */
                        }
                        {
                        /*
                            starts: Below: If user comes to mint page and is has not connected wallet.
                        */
                        }

                        {
                           (Loaction.state!=null) &&  ((account!==undefined)===true) && active && navigateMeToMintPage()
                        }

                        {
                           // console.log("Status:" ,account!==undefined )
                           ((account!==undefined)===true) && active && nftHolding!==100 && navigate('/noavatarz')
                            //active && ((account!==undefined) === true) && nftHolding<=0 && navigate('/noavatarz')
                            //<Navigate to="/wallets" />
                        }
                        {
                          // active && nftHolding>0 && navigate('/wallets')

                          active && nftHolding!==100 && nftHolding>0 && navigate('/wallets')
                        }
                    </div>
                    
                    {/* */}
                </div>
            </div>
            
            <div>
            </div>
        </section>
    );
}

export default Wallet;
