import "./Wallets.css";
import A_3D_30fps from "./Wallet_Assets/A_3D_30fps.gif";
import frame_avatarzzz from "./Wallet_Assets/frame_avatarzzz.png";
import avatarzzz_logo_white from "./Wallet_Assets/avatarzzz_logo_white.svg";
import { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { abi } from "../../abi";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import configFile from "../../configFile.json";
import { Network, Alchemy } from "alchemy-sdk";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../CartRedux/cartSlice";
import axios from "axios";

function Wallets() {
  $(".swiper-button-next").appendTo($(".slider_navi"));
  $(".swiper-button-prev").appendTo($(".slider_navi"));

  /* Starts: Alchemy Setting */
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEY_KEY,
    network: Network.ETH_MAINNET,
  };
  const alchemy = new Alchemy(settings);
  /* Ends: Alchemy Setting */

  const navigate = useNavigate();

  const { account, library: provider } = useWeb3React();

  const [USER_ADDRESS, set_USER_ADDRESS] = useState({
    solana: "",
    polygon: "",
    flow: "",
    algorand: "",
    binance: "",
    ethereum: account,
  });

  const [addd, setAdd] = useState([]);

  const SignThisMessage = `nftlab.berlin wants you to sign in with your Ethereum account to confirm identity. Connected Ethereum Account: ${account}`;

  const solanaInputBox = useRef("");
  const flowInputBox = useRef("");
  const polygonInputBox = useRef("");
  const algorandInputBox = useRef("");
  const binanceInputBox = useRef("");

  const dispatch = useDispatch();
  //dispatch(cartActions.setShowCart());
  let up_me = useSelector((state) => state.cart.showCart);
  //console.log("ME: ", up_me);

  useEffect(() => {
    dispatch(cartActions.setShowCart());
  }, []);

  useEffect(() => {
    //console.log("Accout changed: use Effect");

    if ((account !== undefined) === true) {
      const ele = document.getElementById("changeMeToDisconnect");
      ele.innerHTML = `&nbsp;&nbsp;&nbsp;${account.substring(
        0,
        4
      )} ... ${account.substring(account.length - 4)}`;

      // $( '.nav_wallet_item').after(`<><div className="walletIconLogin hel"> <Link to="/Wallets" ><img src=${avatarzzz_logo} alt='nftberlinIcon'/><p>My Avatarzzz</p></Link> </div> </>`);
    }

    getUserNftHoldings();
  }, [account]);

  /* This is the function to send post data to server with body */

  const [addressStatus, setAddressStatus] = useState(null);

  const postDataToSever = async (e) => {
    e.preventDefault();

    const signer = provider.getSigner();
    const signedMessage = await signer.signMessage(SignThisMessage);
    console.log("SIGNED MESSAGE IS: ", signedMessage);
    const signature = signedMessage;
    const signerAddr = ethers.utils.verifyMessage(
      SignThisMessage,
      signedMessage
    );
    console.log("SIGNER ADDR: ", signerAddr);

    const { solana, polygon, flow, algorand, binance, ethereum } = USER_ADDRESS;

    // const response = await fetch(`${process.env.REACT_APP_DATABASE_LINK}/saveaddress`, {
    const response = await fetch(
      `${process.env.REACT_APP_ENGAGEMENT_DBLINK}/savewalletaddress`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          account,
          signedMessage,
          signature,
          solana,
          polygon,
          flow,
          algorand,
          binance,
          ethereum,
        }),
      }
    );

    const responseStatus = await response.json();
    console.log("The server responded with status code: ", responseStatus);

    const { status } = response;
    if (status === 201) {
      setAddressStatus(status);
    } else {
      setAddressStatus(900);
    }
  };

  let name, value;

  const handleInput = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;
    set_USER_ADDRESS({ ...USER_ADDRESS, [name]: value });
  };

  /* CODE STARTS -> SAVE EMAIL TO SERVER */
  /* */
  const [emailStatus, setEmailStatus] = useState(null);

  const postEmailToServer = async (e) => {
    e.preventDefault();
    console.log(document.getElementById("emailss").value);
    let tempemail = document.getElementById("emailss").value;
    const response = await fetch(
      `${process.env.REACT_APP_DATABASE_LINK}/savemymail`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          tempemail,
        }),
      }
    );

    const responseStatus = await response.json();
    const { status } = response;
    if (status === 201) {
      setEmailStatus(status);
    } else {
      setEmailStatus(900);
    }
    console.log("The server responded with status code: ", responseStatus);
    console.log("Email is sent to server: ", status);
  };

  /* CODE ENDS -> SAVE EMAIL TO SERVER */

  async function getUserNftHoldings() {
    /* Starts: infura fix */
    //const InfuraProvider = process.env.INFURA_PROVIDER;
    //console.log(InfuraProvider);
    // const providesr = new ethers.providers.JsonRpcProvider(configFile.INFURA_PROVIDER);

    const providesr = new ethers.providers.InfuraProvider(
      "homestead",
      process.env.REACT_APP_INFURA_API
    );

    /* Starts: infura fix */
    //const providesr = new providers.InfuraProvider(5, '898138f8743544709e5c7dd3987e063b')
    console.log("Provider: ", provider);

    const contractAddress = configFile.CONTRACT_ADDRESS;
    let CONTRACT = new ethers.Contract(contractAddress, abi, providesr);

    let findMyToken = account;
    const balance = await CONTRACT.balanceOf(findMyToken);
    //let arr = [];
    let tempURI = [];

    if (balance == 0) {
      //console.log("NO NFT FOUND FOR YOUR ADDRESS");
      navigate("/noavatarz");
    } else {
      /*
            while(count < balance) {

            if(await CONTRACT.ownerOf(i) == findMyToken)
                {
                    let x = await CONTRACT.tokenURI(i)
                    tempURI.push(x.slice(54).slice(0, -5));

                    //tempURI.push(await CONTRACT.tokenURI(i));
                    count++;
                }
                i++;
                //console.log("arr: ", arr);
            }
            console.log("slice: ", tempURI[0].slice(54).slice(0, -5));
            // console.log("TOKEN URI YOU ARE HOLDING: ", await CONTRACT.tokenURI(arr[0]));
            setAdd(tempURI);
            console.log("TEMP_URI:  ", tempURI);
            
            */

      getMyAddress();

      const data = await alchemy.nft.getNftsForOwner(account, {
        contractAddresses: [contractAddress],
      });
      console.log(data);
      for (let i = 0; i < data.totalCount; i++) {
        let x = data.ownedNfts[i].tokenUri.raw;
        console.log("", data.ownedNfts[i].tokenUri.raw);
        tempURI.push(x.slice(50).slice(0, -5));
      }
      setAdd(tempURI);
      //console.log("TEMP_URI:  ", tempURI);
    }
  }

  async function getMyAddress() {
    const signer = provider.getSigner();
    const signedMessage = await signer.signMessage(SignThisMessage);
    console.log("SIGNED MESSAGE IS: ", signedMessage);

    const signerAddr = ethers.utils.verifyMessage(
      SignThisMessage,
      signedMessage
    );
    console.log("SIGNER ADDR: ", signerAddr);

    axios
      .post(`${process.env.REACT_APP_ENGAGEMENT_DBLINK}/getwalletaddress`, {
        account: account,
        signature: signedMessage,
      })
      .then(function (response) {
        store(response)
          .then(() => {
            document.getElementById("solanaInputBox").value =
              solanaInputBox.current;
            document.getElementById("polygonInputBox").value = polygonInputBox;
            document.getElementById("flowInputBox").value = flowInputBox;
            document.getElementById("algorandInputBox").value =
              algorandInputBox;
            document.getElementById("binanceInputBox").value = binanceInputBox;
            set_USER_ADDRESS({
              ...USER_ADDRESS,
              ["solana"]: solanaInputBox.current,
              ["polygon"]: polygonInputBox.current,
              ["flow"]: flowInputBox.current,
              ["algorand"]: algorandInputBox.current,
              ["binance"]: binanceInputBox.current,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function store(response) {
    solanaInputBox.current = response.data.solana;
    polygonInputBox.current = response.data.polygon;
    flowInputBox.current = response.data.flow;
    algorandInputBox.current = response.data.algorand;
    binanceInputBox.current = response.data.binance;
  }


  return (

    <div>
      { account!==undefined===true ? (
    <section className="wallets">
      

      <div className="wallets_container">
        <div className="wallets_content">
          <div className="wallets_logo">
            <img src={A_3D_30fps} alt="" />
          </div>

          {/* Render Minted NFT and transferred 
                Logic:
                1. useWeb3() -> get connected account
                2. fetch nft from contract
                */}

          <div className="slider_container">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="wallets_img mySwiper"
            >
              {addd.map((temp) => {
                return (
                  <SwiperSlide className="wallets_img_content" key={`${temp}`}>
                    <img
                      src={frame_avatarzzz}
                      width="250px"
                      height="250px"
                      alt=""
                    />
                    {/* <img className="img2" src={`https://gateway.pinata.cloud/ipfs/QmVpjwXJYjhLtUkNzBZvFSf52FESgqHY1eBcmzZi8Y5DPW/${temp}.png`} width="250px" height="250px" alt={`${temp}`}  /> */}
                    <img
                      className="img2"
                      src={`https://storage.googleapis.com/ethnft_compress/${temp}.jpg`}
                      width="250px"
                      height="250px"
                      alt={`${temp}`}
                    />
                    <p>Avatarzzz {temp}</p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="slider_navi"></div>
          </div>

          <p className="wallets_text wallets_discount">
          Get a shiny 90% Ticket dizzzcount for this years NFC Lisbon. <a href="https://www.nonfungibleconference.com/get-a-ticket" target="_blank">Open the Ticket page</a> and use Promo Code: C34C8AD7 <br/>
          (Please note that this discount reduces by 5% every week!)
          </p>

          <p className="wallet_telegram">
          Connect with fellow Avatarzzz holders around the world: <a href="https://t.me/+OxTZZHbtp5VhOTQy" target="_blank">Join Telegram Group</a>
          </p>
          <h1 className="wallets_title">ONE NFT TO RULE THEM ALL</h1>

          <p className="wallets_text">
            Add an address for your Avatarzzz NFT to be airdropped once
            available on each chain. Future use cases, benefits and exclusive
            utilities can appear on any of the listed chains /// So stay tuned
            and make sure to follow our social media for regular updates.
          </p>

          <div className="wallets_adresse">
            {/* FORM TO SEND TO mongoDb */}
            <form method="post" className="userAddressForm">
              <div className="input_container">
                <div className="solana_adresse adresse">
                  <div className="input_field">
                    <input
                      type="text"
                      id="solanaInputBox"
                      name="solana"
                      value={USER_ADDRESS.solana}
                      onChange={handleInput}
                      placeholder="solana address "
                    />
                  </div>
                </div>
                <div className="wallets_avLogo">
                  <img src={avatarzzz_logo_white} alt="" />
                </div>
              </div>

              <div className="input_container">
                <div className="polygon_adresse adresse">
                  <div className="input_field">
                    <input
                      type="text"
                      id="polygonInputBox"
                      name="polygon"
                      value={USER_ADDRESS.polygon}
                      onChange={handleInput}
                      placeholder={`polygon address`}
                    />
                  </div>
                </div>
                <div className="wallets_avLogo">
                  <img src={avatarzzz_logo_white} alt="" />
                </div>
              </div>

              <div className="input_container">
                <div className="flow_adresse adresse">
                  <div className="input_field">
                    <input
                      type="text"
                      id="flowInputBox"
                      name="flow"
                      value={USER_ADDRESS.flow}
                      onChange={handleInput}
                      placeholder="flow address "
                    />
                  </div>
                </div>
                <div className="wallets_avLogo">
                  <img src={avatarzzz_logo_white} alt="" />
                </div>
              </div>

              <div className="input_container">
                <div className="algorand_adresse adresse">
                  <div className="input_field">
                    <input
                      type="text"
                      id="algorandInputBox"
                      name="algorand"
                      value={USER_ADDRESS.algorand}
                      onChange={handleInput}
                      placeholder="algorand address "
                    />
                  </div>
                </div>
                <div className="wallets_avLogo">
                  <img src={avatarzzz_logo_white} alt="" />
                </div>
              </div>

              <div className="input_container">
                <div className="binance_adresse adresse">
                  <div className="input_field">
                    <input
                      type="text"
                      id="binanceInputBox"
                      name="binance"
                      value={USER_ADDRESS.binance}
                      onChange={handleInput}
                      placeholder="binance address "
                    />
                  </div>
                </div>
                <div className="wallets_avLogo">
                  <img src={avatarzzz_logo_white} alt="" />
                </div>
              </div>

              <div className="input_container">
                <div className="ether_adresse adresse">
                  <div className="input_field">
                    {/* <input type="text" name="ethereum" value={USER_ADDRESS.ethereum} onChange={handleInput}  placeholder="nothing to do. you are all set up here" /> */}
                    <input
                      className="changePlaceholderColor"
                      readOnly
                      type="text"
                      name="ethereum"
                      placeholder={account}
                    />
                  </div>
                </div>
                <div className="wallets_avLogo">
                  <img src={avatarzzz_logo_white} alt="" />
                </div>
              </div>

              <div className="emailContainer dimMe">
                {addressStatus === 201 ? (
                  <p className="saveEmailSuccess2">Success, address saved</p>
                ) : (
                  <></>
                )}
                <div className="save_btn stopTranform">
                  {USER_ADDRESS.solana.length > 0 ||
                  USER_ADDRESS.polygon.length > 0 ||
                  USER_ADDRESS.flow.length > 0 ||
                  USER_ADDRESS.algorand.length > 0 ||
                  USER_ADDRESS.binance.length > 0 ? (
                    <>
                      <button type="submit" onClick={postDataToSever}>
                        {" "}
                        SIGN UP FOR FREE NFT AIRDROP
                      </button>
                      {document.querySelector(".stopTranform") != null ? (
                        document
                          .querySelector(".stopTranform")
                          .classList.remove("stopTranform")
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        className="dimMe"
                        type="submit"
                        onClick={postDataToSever}
                      >
                        {" "}
                        SIGN UP FOR FREE NFT AIRDROP
                      </button>
                      {document.querySelector(".save_btn") != null ? (
                        document
                          .querySelector(".save_btn")
                          .classList.add("stopTranform")
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>

            {/* <button onClick={getMyAddress}> Click to retrve your old Addres</button> */}

            <div className="wallets_email">
              <p>
                Inform me when my Avatarzzz NFT is available on any of those
                blockchains:
              </p>
              {/* Validation Below*/}

              <form onSubmit={postEmailToServer}>
                <span>Your Email:</span>
                <div className="emailInputContainer">
                  <input
                    type="email"
                    id="emailss"
                    name="email"
                    placeholder="..."
                    required
                  />
                  {emailStatus === 201 ? (
                    <p className="saveEmailSuccess1">Successful</p>
                  ) : (
                    <></>
                  )}
                </div>
                <button type="submit">Sign me up</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      
    </section>
    ) : <div className="noNftN"><p>Gm! There is nothing here. Click on Home to continue</p></div>}
    </div>
  );
}

export default Wallets;
