import './App.css';
import Nav from './components/Nav/Nav';
import Header from './components/Header/Header';
import TheLab from './components/TheLab/TheLab';
import Footer from './components/Footer/Footer';
import Services from './components/Services/Services';
import About from './components/About/About';
import Avatarzzz from './components/Avatarz/Avartarzzz';
import Roadmap from './components/Roadmap/Roadmap';
import Mint from './components/Mint/Mint';
import { Navigate, Route, Routes } from 'react-router-dom';
import Wallet from './components/WalletConnect/Wallet';
import NoAvatarz from './components/NoAvatarz/NoAvatarz';
import Wallets from './components//Wallets/Wallets';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react';
import ScrollToTop from './components/ScrollRestore/ScrollTop';
import { Buffer } from 'buffer';
import Engagement from './components/Engagement/Engagement';
import ShinyTicket from './components/ShinyTicket/ShinyTicket';

window.Buffer = window.Buffer || Buffer;

const getLibrary = (provider) => {    
  return new Web3Provider(provider);
}

function App() {

  useEffect(() =>{  
  
    $(document).ready(function(){
        $('.nav_list li a , .hamburger_overlay li a').click(function(){
          $('li a').removeClass("activelink");
          $(this).addClass("activelink");
      });
    });
    
    $(".hamburger").click(function(){                         
      $(".span_tl").addClass("span_tl_animate");
      $(".span_tr").addClass("span_tr_animate");
      $(".span_bl").addClass("span_bl_animate");
      $(".span_br").addClass("span_br_animate");
      $(".hamburger_overlay").addClass("overlay-show");
      $(".close_hamburger").addClass("close_hamburger2");
      $(".nav_wallet").addClass("show_wallet_connect");
  });
  
  $(".hamburger_list li a").click(function(){
  
      $(".hamburger_overlay").removeClass("overlay-show");
      $(".span_tl").removeClass("span_tl_animate");
      $(".span_tr").removeClass("span_tr_animate");
      $(".span_bl").removeClass("span_bl_animate");
      $(".span_br").removeClass("span_br_animate");
      $(".nav_wallet").removeClass("show_wallet_connect");
      $(".close_hamburger").removeClass("close_hamburger2");
  });

  $(".nav_wallet").click(function(){
  
    $(".hamburger_overlay").removeClass("overlay-show");
    $(".span_tl").removeClass("span_tl_animate");
    $(".span_tr").removeClass("span_tr_animate");
    $(".span_bl").removeClass("span_bl_animate");
    $(".span_br").removeClass("span_br_animate");
    $(".nav_wallet").removeClass("show_wallet_connect");
    $(".close_hamburger").removeClass("close_hamburger2");
});

  $(".close_hamburger").click(function(){
    $(".span_tl").removeClass("span_tl_animate");
    $(".span_tr").removeClass("span_tr_animate");
    $(".span_bl").removeClass("span_bl_animate");
    $(".span_br").removeClass("span_br_animate");
    $(".hamburger_overlay").removeClass("overlay-show");
    $(".close_hamburger").removeClass("close_hamburger2");
    $(".nav_wallet").removeClass("show_wallet_connect");
});
  
    $(".show-btn1").click(function(){
      $(".tow-col_accordion-hide1").addClass("show-c");
      $(".show-btn1").addClass("hide-c");
  });
  $(".close-btn1").click(function(){
      $(".tow-col_accordion-hide1").removeClass("show-c");
      $(".show-btn1").removeClass("hide-c");
  });
  
  $(".show-btn2").click(function(){
      $(".tow-col_accordion-hide2").addClass("show-c");
      $(".show-btn2").addClass("hide-c");
  });
  $(".close-btn2").click(function(){
      $(".tow-col_accordion-hide2").removeClass("show-c");
      $(".show-btn2").removeClass("hide-c");
  });
  
    $(".impressum-open").click(function(){
      $(".impressum-accordion").addClass("show-c");
      $(".impressum-accordion").addClass("show-ac");
      $(".impressum-close").addClass("display-show");
      $(".impressum-open").addClass("display-none");
  });
  
  $(".impressum-close").click(function(){
      $(".impressum-accordion").removeClass("show-c");
      $(".impressum-accordion").removeClass("show-ac");
      $(".impressum-close").removeClass("display-show");
      $(".impressum-open").removeClass("display-none");
  });

  $(".wallet-title").click(function(){                         

    $(".hamburger_overlay").removeClass("overlay-show");
    $(".span_tl").removeClass("span_tl_animate");
    $(".span_tr").removeClass("span_tr_animate");
    $(".span_bl").removeClass("span_bl_animate");
    $(".span_br").removeClass("span_br_animate");

 
});
  
  
},[]);

  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
      <ScrollToTop />
      <Nav />
      <Routes>
        <Route exact path="/" element={ <> <Header /> <TheLab /> <Services /> <About /> <Avatarzzz /> <Roadmap /> </>}> </Route>
        <Route exact path="/home" element={ <> <Header /> <TheLab /> <Services /> <About /> <Avatarzzz /> <Roadmap /> </>}> </Route>
        <Route exact path="/mint" element={ <Mint /> }> </Route>
        <Route exact path="/connectwallet" element={<Wallet/> }> </Route>
        <Route exact path="/wallets" element={<Wallets />}></Route>
        <Route exact path="/noavatarz" element={<NoAvatarz />}></Route>

        <Route exact path="/shinyticket" element={<ShinyTicket />}></Route>
        

        <Route path='*' element={ <Navigate to='/' /> }></Route>
      </Routes>
      <Footer />
      </Web3ReactProvider> 
    </> 
  );
}

export default App;


