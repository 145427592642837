import './Footer.css';
import solana from './Footer_Assets/solana.svg';
import ether from './Footer_Assets/ether.svg';
import flow from './Footer_Assets/flow.svg';
import algorand from './Footer_Assets/algorand.svg';
import binance from './Footer_Assets/binance.svg';
import polygon from './Footer_Assets/polygon.svg';
import tc from './Footer_Assets/tc.pdf';
import { useState } from 'react';

function Footer() {

    // const form = document.getElementById('sib-form')

    // form.addEventListener('submit', (event) => {
    // event.preventDefault()
    // console.log("form")
  
    // })

    const [status, setStatus] = useState(500);

    const submit = async function submitx(e) {
        console.log(e);
        e.preventDefault();
        let response;
        // Getting data from input box
        let temp = document.getElementById('EMAIL').value;
        console.log("temp: ", temp);
        try {
            response = await fetch(`${process.env.REACT_APP_DATABASE_LINK}/savemynewslettermail`, {
                method: "POST",
                headers: {
                    "Content-Type" : "application/json"
                },
    
                body: JSON.stringify({
                    "email": temp
                })
        });

        console.log("Response is: ", response);
        if (response.status === 201) {
            /* alert("Successfull"); */
            setStatus(100);
        } 
        else {
            /* alert("falied"); */
            setStatus(0);
        }

    } 
    
        catch (err) {
            console.log("error is ",err);
        }
    }

    return(
        <footer className="footer">
        <div className="footer_container">
            <div className="footer_left">
                <div className="footer_left_firstItem">
                <p>We work with:</p>
                            <div className="footer_left_firstItem_imgContainer">
                                <a href="https://solana.com/de" target="_blank" rel="noreferrer">
                                    <img src={solana} alt="Avatarzzz NFT" />
                                </a>
                            </div>
                            <div className="footer_left_firstItem_imgContainer">
                                <a href="https://ethereum.org/" target="_blank" rel="noreferrer">
                                    <img src={ether} alt="Avatarzzz NFT" />
                                </a>
                            </div>
                            <div className="footer_left_firstItem_imgContainer">
                                <a href="https://flow.com/" target="_blank" rel="noreferrer">
                                    <img src={flow} alt="Avatarzzz NFT" />
                                </a>
                            </div>
                            <div className="footer_left_firstItem_imgContainer">
                                <a href="https://www.algorand.com/" target="_blank" rel="noreferrer">
                                    <img src={algorand} alt="Avatarzzz NFT" />
                                </a>
                            </div>
                            <div className="footer_left_firstItem_imgContainer">
                                <a href="https://www.binance.com/" target="_blank" rel="noreferrer">
                                    <img src={binance} alt="Avatarzzz NFT" />
                                </a>
                            </div>
                            <div className="footer_left_firstItem_imgContainer">
                                <a href="https://polygon.technology/" target="_blank" rel="noreferrer">
                                    <img src={polygon} alt="Avatarzzz NFT" />
                                </a>
                            </div>
                </div>
            </div>
            <div>

            <div className="sib-form newsletter" id="newsletter">
                <div id="sib-form-container" className="sib-form-container">


                <div id="sib-container" className="sib-container--large sib-container--vertical">

                    <form id="sib-form" className="newsletter_container" onSubmit={submit} >
                        <div>
                            <div className="sib-input sib-form-block">
                            <div className="form_entry entry_block newsletter_email_container">
                                <div className="form_label-row newsletter_email">
                                <label className="entry_label" htmlFor="EMAIL" data-required="*">
                                    Newsletter
                                </label>
                
                                <div className="entry_field">
                                    <input className="input" type="Email" id="EMAIL" name="EMAIL" autoComplete="off" placeholder="Your email" data-required="true" required />
                                </div>
                                </div>

                                {
                                        // 0 -> Error
                                        // 100 -> Success 
                                    }

                                    {
                                        status===0 ? <p className='newsletterError'>Error</p>:<></>
                                    }
                                    {
                                        status===100 ? <p className='newsletterSuccess'>Success</p>:<></>
                                    }

                                <label className="entry_error entry_error--primary">
                                </label>
                            </div>
                            </div>
                        </div>

                        <div>
                            <div className="sib-optin sib-form-block">
                            <div className="form_entry entry_mcq">
                                <div className="form_label-row ">
                                <div className="entry_choice">
                                    <label>
                                    <input type="checkbox" className="input_replaced" value="1" id="OPT_IN" name="OPT_IN" required data-required="true" />
                                    <span className="checkbox checkbox_tick_positive"></span><span ><p>Newsletter terms</p></span> </label>
                                </div>
                                </div>
                                <label className="entry_error entry_error--primary">
                                </label>
                            </div>
                            </div>
                        </div>

                        <div>
                            <div className="sib-form-block">
                            <button className="sib-form-block_button sib-form-block_button-with-loader" form="sib-form" type="submit">
                                <svg className="icon clickable_icon progress-indicator_icon sib-hide-loader-icon" viewBox="0 0 512 512">
                                <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                                </svg>
                                Sign me up
                            </button>
                            </div>
                        </div>
            
                        <input type="text" name="email_address_check" value="abc@gmail.com" className="input--hidden"  required />
                        <input type="hidden" name="locale" value="de" />
                    </form>


                </div>
                </div>
            </div>
            </div>
            <div className="footer_right">
                <ul className="footer_list">
                    <li className="impressum">
                        <a className="impressum-open" >
                            imprint
                        </a>
                        <a className="impressum-close" target="_blank" rel="noreferrer"> close</a>
                        <div className="impressum-accordion">
                            <p>
                                nftlab.berlin <br />
                                A service from chris and friends GmbH <br />
                                Grünbergerstraße 48B <br />
                                10245 Berlin <br />
                                P: 004930469992030 <br />
                                M: hi@nftlab.berlin <br />
                                represented by its shareholders <br />
                                Tax-ID: DE312066890 <br />
                                Liability Insurance: <br />
                                Exali GmbH <br />
                                <a href="https://chris-andfriends.de/datenschutz.html" target="_blank" rel="noreferrer">About privacy</a>
                            </p>
                        </div>
                    </li>
                    <li className="line">|</li>
                    <li>
                        <a href={tc} target="_blank" rel="noreferrer">
                            terms and conditions
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    </footer>
 
    );
}

export default Footer;

